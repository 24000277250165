import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Cryptograf from 'crypto-js';
import { Observable, map, startWith } from 'rxjs';
import { environment } from 'src/environment/environments';

import { Color } from 'echarts';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';

import { AutoridadeService } from 'src/app/core/services/autoridade.service';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { EmpresaService } from 'src/app/core/services/empresa.service';
import { GrupoService } from 'src/app/core/services/grupo.service';
import { MidiaService } from 'src/app/core/services/midia.service';
import { PartidoService } from 'src/app/core/services/partido.service';
import { SentimentoService } from 'src/app/core/services/sentimento.service';
import { SubCategoriaService } from 'src/app/core/services/subcategoria.service';
import { TipoInformacaoService } from 'src/app/core/services/tipo-informacao.service';
import { VeiculoService } from 'src/app/core/services/veiculo.service';

import { autoridade } from 'src/app/core/model/autoridade';
import { categoria } from 'src/app/core/model/categoria';
import { empresa } from 'src/app/core/model/empresa';
import { grupo } from 'src/app/core/model/grupo';
import { midia } from 'src/app/core/model/midia';
import { sentimento } from 'src/app/core/model/sentimento';
import { veiculo } from 'src/app/core/model/veiculo';

import { partido } from 'src/app/core/model/partido';
import { programa } from 'src/app/core/model/programa';
import { subCategoria } from 'src/app/core/model/subcategoria';
import { subGrupo } from 'src/app/core/model/subgrupo';
import { tipoInformacao } from 'src/app/core/model/tipoInformacao';

import { DashboardComunidadeService } from 'src/app/core/services/dashboard-comunidade.service';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import _moment from 'moment';
import { DashboardImprensaService } from 'src/app/core/services/dashboard-imprensa.service';
import { DashboardJuridicoService } from 'src/app/core/services/dashboard-juridico.service';
import { HomeService } from 'src/app/core/services/home.service';
import { PautaService } from 'src/app/core/services/pauta.service';
import { ProgramaService } from 'src/app/core/services/programa.service';
import { SubGrupoService } from 'src/app/core/services/subgrupo.service';
import { DialogAudioComponent } from 'src/app/shared/dialogs/dialog-audio/dialog-audio.component';
import { DialogImgComponent } from 'src/app/shared/dialogs/dialog-img/dialog-img.component';
import { DialogTranscricaoComponent } from 'src/app/shared/dialogs/dialog-transcricao/dialog-transcricao.component';
import { DialogVideoComponent } from 'src/app/shared/dialogs/dialog-video/dialog-video.component';
import { DashboardLegislativoService } from 'src/app/core/services/dashboard-legislativo.service';

interface filterSelected {
  categoria: any;
  subCategoria: any;
  empresa: any;
  grupo: any;
  partido: any;
  programa: any;
  subGrupo: any;
  tipoInformacao: any;
  sentimento: any;
  autoridade: any;
  midia: any;
  veiculo: any;
  cidade: any;
  dataInicial: any;
  dataFinal: any;
  titulo: any;
}

interface MesesAssociados {
  [numero: number]: string;
}

interface chartData {
  dataURL: string;
  imgWidth: number;
  imgHeight: number;
}

@Component({
  selector: 'app-comunidade',
  templateUrl: './comunidade.component.html',
  styleUrls: ['./comunidade.component.scss'],
})
export class ComunidadeComponent implements OnInit {
  moment = _moment;

  //----------------------------------Variaveis de dados dos gráficos------------------------------------

  @ViewChildren('charts') charts: QueryList<any> = <any>[];
  chartDataPDF: chartData = <any>{}; //variavel que vai receber o dataURL do gráfico e o tamanho

  permissaoSuperintendencia: boolean = false;
  permissionCharts: Array<string> = [];

  // midiaMidiaPorTempo: Array<any> | null = null;
  // dataMidiaPorTempo: Array<any> | null = null;
  // dataNoticiaPorMidia: Array<any> | null = null;
  // dataCentrimetragemPorMidia: Array<any> | null = null;
  //dataNoticiaPorPauta: Array<any> | null = null;
  dataNoticiaPorSentimento: Array<any> | null = null;
  dataNoticiaGauge: number | null = null;
  haNoticiasGauche: boolean = true;
  // dataNoticiaPorVeiculo: { veiculos: [] } | null = null;
  dataSentimentoPorCategoria: { categorias: [] } | null = null;
  dataNoticiaPorSubCategoria: { subcategorias: [] } | null = null;
  sentimentoSentimentoPorTempo: Array<any> | null = null;
  dataSentimentoPorTempo: Array<any> | null = null;
  orgaosOrgaoPorTempo: Array<any> | null = null;
  dataOrgaoPorTempo: Array<any> | null = null;
  dataNuvemDePalavras: Array<any> | null = null;
  dataNoticiaPorSentimentoDiario: { datas: [] } | null = null;
  dataSentimentoPorOrgao: { orgaos: [] } | null = null;
  dataSentimentoPorPartido: { partidos: [] } | null = null;
  dataSentimentoPorAutoridade: { autoridades: [] } | null = null;
  dataDetratores: Array<any> | null = null;
  dataEvangelizadores: Array<any> | null = null;
  dataPartidosDetratores: Array<any> | null = null;
  dataPartidosEvangelizadores: Array<any> | null = null;

  typeSelectedNoticiasPorOrgao: string = 'bar';
  typeSelectedNoticiasPorClassificacao: string = 'bar';

  erroPartidoEvangelizadores: boolean = false;
  erroPartidoDetratores: boolean = false;
  erroSentimentoDetratores: boolean = false;
  erroSentimentoEvangelizadores: boolean = false;
  erroAutoridadesPorSentimento: boolean = false;
  erroOrgaosPorTempo: boolean = false;
  erroNoticiaPorSentimento: boolean = false;
  erroSentimentoPorOrgao: boolean = false;
  erroSentimentoPorPartido: boolean = false;
  erroSentimentoPorCategoria: boolean = false;
  erroNoticiaPorSubCategoria: boolean = false;
  erroSentimentoPorTempo: boolean = false;
  erroNoticiaPorSentimentoDiario: boolean = false;
  erroNuvemDePalavras: boolean = false;
  erroTabela: boolean = false;

  coresSentimento = ['#77C4A8', '#F5C869', '#DD6B7F'];
  colorSentimento = <any>[];
  colorSentimentoTempo = <any>[];
  colorMidia = <any>[];
  colorMidiaTempo = <any>[];
  colorOrgaoTempo = <any>[
    '#6379A4',
    '#A97FD1',
    '#F38E5D',
    '#6F5035',
    '#A9916F',
  ];
  // colorPauta = <any>[];
  colorCentrimetragem = <any>[];
  colorDetratores = this.coresSentimento[2];
  colorEvangelizadores = this.coresSentimento[0];

  //----------------------------------Variaveis da Tabela------------------------------------

  @ViewChild(MatPaginator) paginator: MatPaginator = <any>{};
  @ViewChild(MatSort) sort: MatSort = <any>{};

  carregandoLenth = true;
  carregandoSent = true;
  positiva = 0;
  negativa = 0;
  neutra = 0;
  length = 0;
  pageIndex = 1;
  pageEvent: PageEvent = <any>{};
  dataTabela = <any>[];
  paginaAtual = 1;
  rowsPerPage = 10;
  ultimaPagina = 1;
  carregandoTabela = true;
  tabelaVazia = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'id',
    'datapubli',
    'grupo',
    'empresa',
    'midia',
    'veiculo',
    'programa',
    'categoria',
    'subcategoria',
    'sentimento',
    'titulo',
  ];

  Meses: MesesAssociados = {
    1: 'JANEIRO',
    2: 'FEVEREIRO',
    3: 'MARÇO',
    4: 'ABRIL',
    5: 'MAIO',
    6: 'JUNHO',
    7: 'JULHO',
    8: 'AGOSTO',
    9: 'SETEMBRO',
    10: 'OUTUBRO',
    11: 'NOVEMBRO',
    12: 'DEZEMBRO',
  };
  grupospdf: string = '';
  empresaspdf: string = '';

  //----------------------------------Variaveis dos Filtros------------------------------------

  @ViewChild('completeCategoria') completeCategoria: MatSelect = <any>{};
  @ViewChild('completeGrupo') completeGrupo: MatSelect = <any>{};
  @ViewChild('completePrograma') completePrograma: MatSelect = <any>{};
  @ViewChild('completeEmpresa') completeEmpresa: MatSelect = <any>{};
  @ViewChild('completeAutoridade') completeAutoridade: MatSelect = <any>{};
  @ViewChild('completeMidia') completeMidia: MatSelect = <any>{};
  @ViewChild('completeVeiculo') completeVeiculo: MatSelect = <any>{};
  @ViewChild('completeSentimento') completeSentimento: MatSelect = <any>{};
  @ViewChild('completeSubGrupo') completeSubGrupo: MatSelect = <any>{};
  @ViewChild('completeTipoInformacao') completeTipoInformacao: MatSelect = <
    any
  >{};

  panelExpansion: boolean = false;
  erroCarregamentoFiltros: boolean = false;

  disableFiltros: boolean = false;
  carregandoFiltros: boolean = false;
  carregandoFiltrosInit: boolean = false;

  haAlteracaoFiltroGrupo: boolean = false;
  haAlteracaoFiltroEmpresa: boolean = false;
  haAlteracaoFiltroInformacao: boolean = false;
  haAlteracaoFiltroSubGrupo: boolean = false;
  haAlteracaoFiltroCategoria: boolean = false;
  haAlteracaoFiltroPrograma: boolean = false;
  haAlteracaoFiltroAutoridade: boolean = false;
  haAlteracaoFiltroVeiculo: boolean = false;
  haAlteracaoFiltroMidia: boolean = false;
  haAlteracaoFiltroSentimento: boolean = false;

  needFilterWordCloud: boolean = false;
  haFiltroParaNuvem: boolean = false;

  categorias: Array<categoria> = [];
  grupos: Array<grupo> = [];
  partidos: Array<partido> = [];
  programas: Array<programa> = [];
  empresas: Array<empresa> = [];
  sentimentos: Array<sentimento> = [];
  autoridades: Array<autoridade> = [];
  midias: Array<midia> = [];
  veiculos: Array<veiculo> = [];

  subCategorias: Array<subCategoria> = [];
  subGrupos: Array<subGrupo> = [];
  tiposInformacao: Array<tipoInformacao> = [];

  filteredOptionsCategorias?: Observable<categoria[]>;
  filteredOptionsGrupos?: Observable<grupo[]>;
  filteredOptionsProgramas?: Observable<programa[]>;
  filteredOptionsEmpresas?: Observable<empresa[]>;
  filteredOptionsSentimentos?: Observable<sentimento[]>;
  filteredOptionsAutoridades?: Observable<autoridade[]>;
  filteredOptionsMidias?: Observable<midia[]>;
  filteredOptionsVeiculos?: Observable<veiculo[]>;

  filteredOptionsSubGrupos?: Observable<subGrupo[]>;
  filteredOptionsTiposInformacao?: Observable<tipoInformacao[]>;

  filterSelected: filterSelected = {} as filterSelected;
  filterTrigger: filterSelected = {} as filterSelected;
  chipShow = <any>[];
  minDate = <Date>{};
  maxDate = new Date();
  todayDate = new Date();
  thirtyDaysAgo = new Date(new Date().setDate(this.todayDate.getDate() - 30));

  filterForm = new FormGroup({
    categorias: new FormControl(),
    grupos: new FormControl(),
    programas: new FormControl(),
    empresas: new FormControl(),
    sentimentos: new FormControl(),
    autoridades: new FormControl(),
    midias: new FormControl(),
    veiculos: new FormControl(),
    subGrupos: new FormControl({ value: '', disabled: true }),
    tipoInformacao: new FormControl(),

    searchTextboxCategoria: new FormControl(''),
    searchTextboxGrupo: new FormControl(''),
    searchTextboxPrograma: new FormControl(''),
    searchTextboxEmpresa: new FormControl(''),
    searchTextboxSentimento: new FormControl(''),
    searchTextboxAutoridade: new FormControl(''),
    searchTextboxMidia: new FormControl(''),
    searchTextboxVeiculo: new FormControl(''),
    searchTextboxSubGrupo: new FormControl(''),
    searchTextboxTipoInformacao: new FormControl(''),

    dataInicial: new FormControl<any | null>({
      disabled: true,
      value: this.thirtyDaysAgo,
    }),
    dataFinal: new FormControl<any | null>({
      disabled: true,
      value: this.todayDate,
    }),
    titulo: new FormControl(null),
  });

  //----------------------------------Variaveis de Responsividade------------------------------------
  isTelaMenorQue500px: number = 4;
  numbergraphs: number = 2;
  numbertitle: number = 3;
  ResponsiveCards: number = 2;

  constructor(
    private cdr: ChangeDetectorRef,
    private categoriaService: CategoriaService,
    private grupoService: GrupoService,
    private empresaService: EmpresaService,
    private sentimentoService: SentimentoService,
    private dashboardImprensaService: DashboardImprensaService,
    private dashboardComunidadeService: DashboardComunidadeService,
    private dashboardJuridicoService: DashboardJuridicoService,
    private dashboardLegislativoService: DashboardLegislativoService,
    private autoridadeService: AutoridadeService,
    private midiaService: MidiaService,
    private veiculoService: VeiculoService,
    private pautaService: PautaService,
    private programaService: ProgramaService,
    private subGrupoService: SubGrupoService,
    private tipoInformacaoService: TipoInformacaoService,
    private homeService: HomeService,
    public dialog: MatDialog
  ) {
    // Iniciando o dataSource com a tabela vazia
    this.dataSource = new MatTableDataSource(this.dataTabela);
  }

  async ngOnInit(): Promise<void> {
    let permSuperin = Cryptograf.AES.decrypt(
      JSON.parse(localStorage.getItem('permissionSubgrupo') as string),
      environment.cryptoKey
    );

    this.permissaoSuperintendencia = JSON.parse(
      permSuperin.toString(Cryptograf.enc.Utf8)
    ).includes(4);

    let permCharts = Cryptograf.AES.decrypt(
      JSON.parse(localStorage.getItem('permissionCharts') as string),
      environment.cryptoKey
    );

    this.permissionCharts = JSON.parse(
      permCharts.toString(Cryptograf.enc.Utf8)
    ).find(
      (chart: { id_conteudo: number; nome: Array<string> }) =>
        chart.id_conteudo === 4
    ).nome;

    this.homeService.enviarVariavel(4);
    this.dashboardJuridicoService.cancelarChamada();
    this.dashboardImprensaService.cancelarChamada();
    this.dashboardLegislativoService.cancelarChamada();
    this.cancelarCarregamentoFiltros();

    this.onResize();

    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);
    this.filterSelected.tipoInformacao = 1;
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.showchips();

    var request = this.criarStringRequest();

    this.carregandoFiltros = true;
    this.carregandoFiltrosInit = true;
    this.needFilterWordCloud = false;
    this.carregandoLenth = true;
    this.carregandoSent = true;
    this.haNoticiasGauche = true;
    //-------------Carregamento dos gráficos----------------

    await Promise.all([
      Promise.all([
        this.listar_tipoInformacao(),
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ])
        .then(() => {
          this.erroCarregamentoFiltros = false;
          this.carregandoFiltros = false;
          this.carregandoFiltrosInit = false;
          this.onResize();
          setTimeout(() => {
            this.completeTipoInformacao.options.forEach((element) => {
              if (element.value == 'Notícias/Publicações') {
                element.select();
              }
            });
          }, 100);
        })
        .catch((error) => {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }),
      Promise.all([
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoDetratores(request)
            .subscribe({
              next: (value) => {
                this.dataDetratores = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoDetratores(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoEvangelizadores(request)
            .subscribe({
              next: (value) => {
                this.dataEvangelizadores = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoEvangelizadores(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .autoridadesPorSentimento(request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorAutoridade = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarAutoridadesPorSentimento(1);
                reject(true);
              },
            });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService.orgaosPorTempo(request).subscribe({
            next: (value) => {
              this.orgaosOrgaoPorTempo = value.orgaos;
              this.dataOrgaoPorTempo = value.datas;
              resolve(true);
            },
            error: (error) => {
              this.recarregarOrgaosPorTempo(1);
              reject(true);
            },
          });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .noticiaPorSentimento(request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSentimento = value;
                this.colorSentimento = this.setColorSentimento(
                  this.dataNoticiaPorSentimento
                );
                this.positiva = 0;
                this.negativa = 0;
                this.neutra = 0;
                value.forEach((element: any) => {
                  if (element[0] == 'POSITIVA') this.positiva = element[1];
                  if (element[0] == 'NEGATIVA') this.negativa = element[1];
                  if (element[0] == 'NEUTRA') this.neutra = element[1];
                });
                if (this.positiva + this.negativa > 0) {
                  this.dataNoticiaGauge =
                    this.positiva / (this.positiva + this.negativa);
                } else if (this.neutra > 0) {
                  this.haNoticiasGauche = false;
                }
                this.carregandoSent = false;
                resolve(true);
              },
              error: (error) => {
                this.recarregarNoticiaPorSentimento(1);
                reject(true);
              },
            });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoPorOrgao(request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorOrgao = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoPorOrgao(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoPorCategoria(request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorCategoria = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoPorCategoria(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=1&per_page=${
                this.rowsPerPage
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;

                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.carregandoLenth = false;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.ultimaPagina = value._metadata.total_pages;

                resolve(true);
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
              },
              error: (error) => {
                reject(true);
                this.carregandoTabela = false;
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoPorTempo(request)
            .subscribe({
              next: (value) => {
                this.sentimentoSentimentoPorTempo = value.sentimentos;
                this.dataSentimentoPorTempo = value.datas;
                this.colorSentimentoTempo = this.setColorSentimento(
                  this.sentimentoSentimentoPorTempo
                );
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoPorTempo(1);
                reject(true);
              },
            });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .noticiaPorSubCategoria(request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSubCategoria = value;

                resolve(true);
              },
              error: (error) => {
                this.recarregarNoticiaPorSubCategoria(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .nuvemDePalavras(this.haFiltroParaNuvem, request)
            .subscribe({
              next: (value) => {
                this.dataNuvemDePalavras = value;
                resolve(true);
              },
              error: (error) => {
                if (error.status != 400) {
                  this.recarregarNuvemDePalavras(1);
                } else {
                  this.needFilterWordCloud = true;
                }
                reject(true);
              },
            });
        }).catch((error) => {
          // Trate o erro aqui conforme necessário
        }),
      ]),
    ]);
  }

  //----------------------------- Responsividade dos filtros ------------------------------------
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth <= 768) {
      this.isTelaMenorQue500px = 1;
      this.numbergraphs = 1;
      this.numbertitle = 1;
      this.ResponsiveCards = 1;
    } else {
      this.isTelaMenorQue500px = 4;
      this.numbergraphs = 2;
      this.ResponsiveCards = 2;

      if (this.permissaoSuperintendencia) {
        this.numbertitle = 4;
      } else {
        this.numbertitle = 4;
      }
    }
  }

  // ------------------------------ exibir e esconder os chips ---------------------------------------

  showchips() {
    this.chipShow = [];

    if (
      this.filterTrigger.grupo != undefined &&
      this.filterTrigger.grupo.length > 0 &&
      this.filterForm.get('grupos')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.grupo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.grupo.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.programa != undefined &&
      this.filterTrigger.programa.length > 0 &&
      this.filterForm.get('programas')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.programa.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.programa.split(', ')[i]);
      }
    }

    if (this.filterForm.controls.dataInicial.value != null) {
      let datainicial = this.moment(
        this.filterForm.controls.dataInicial.value
      ).toDate();
      this.chipShow.push(
        `${datainicial.getDate()}/${
          datainicial.getMonth() + 1
        }/${datainicial.getFullYear()}`
      );
    }

    if (this.filterForm.controls.dataFinal.value != null) {
      let datafinal = this.moment(
        this.filterForm.controls.dataFinal.value
      ).toDate();
      this.chipShow.push(
        `${datafinal.getDate()}/${
          datafinal.getMonth() + 1
        }/${datafinal.getFullYear()}`
      );
    }

    if (
      this.filterTrigger.empresa != undefined &&
      this.filterTrigger.empresa.length > 0 &&
      this.filterForm.get('empresas')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.empresa.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.empresa.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.midia != undefined &&
      this.filterTrigger.midia.length > 0 &&
      this.filterForm.get('midia')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.midia.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.midia.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.veiculo != undefined &&
      this.filterTrigger.veiculo.length > 0 &&
      this.filterForm.get('veiculo')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.veiculo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.veiculo.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.categoria != undefined &&
      this.filterTrigger.categoria.length > 0 &&
      this.filterForm.get('categorias')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.categoria.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.categoria.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.sentimento != undefined &&
      this.filterTrigger.sentimento.length > 0 &&
      this.filterForm.get('sentimento')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.sentimento.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.sentimento.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.autoridade != undefined &&
      this.filterTrigger.autoridade.length > 0 &&
      this.filterForm.get('autoridade')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.autoridade.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.autoridade.split(', ')[i]);
      }
    }

    if (
      this.filterTrigger.subGrupo != undefined &&
      this.filterTrigger.subGrupo.length > 0 &&
      this.filterForm.get('subGrupos')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.subGrupo.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.subGrupo.split(', ')[i]);
      }
    }
    if (
      this.filterTrigger.tipoInformacao != undefined &&
      this.filterTrigger.tipoInformacao.length > 0 &&
      this.filterForm.get('tipoInformacao')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.tipoInformacao.split(', ').length;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.tipoInformacao.split(', ')[i]);
      }
    }
    if (
      this.filterTrigger.cidade != undefined &&
      this.filterTrigger.cidade.length > 0 &&
      this.filterForm.get('cidade')?.errors?.['invalidWord'] != true
    ) {
      for (
        let i = 0;
        i < this.filterTrigger.cidade.split(', ').length - 1;
        i++
      ) {
        this.chipShow.push(this.filterTrigger.cidade.split(', ')[i]);
      }
    }

    if (
      this.filterForm.controls.titulo.value != '' &&
      this.filterForm.controls.titulo.value != null
    )
      this.chipShow.push(this.filterForm.controls.titulo.value);
  }

  // ------------------------------ funçoes relacionadas aos filtros -----------------------
  async setDate(dateObject: any, onInitiate = false) {
    const datainicial = this.moment(
      this.filterForm.controls.dataInicial.value
    ).toDate();

    const datafinal = this.moment(
      this.filterForm.controls.dataFinal.value
    ).toDate();
    if (datainicial != null) {
      this.minDate = new Date(
        datainicial.getFullYear(),
        datainicial.getMonth(),
        datainicial.getDate()
      );
      this.filterSelected.dataInicial = `${datainicial.getFullYear()}-${
        datainicial.getMonth() + 1
      }-${datainicial.getDate()}`;
    }

    if (datafinal != null) {
      this.maxDate = new Date(
        datafinal.getFullYear(),
        datafinal.getMonth(),
        datafinal.getDate()
      );
      this.filterSelected.dataFinal = `${datafinal.getFullYear()}-${
        datafinal.getMonth() + 1
      }-${datafinal.getDate()}`;
    }

    if (onInitiate == false) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroVeiculo = false;
      this.carregandoFiltros = false;
    }
  }

  validClearFilter() {
    if (
      (this.filterSelected.categoria == undefined ||
        this.filterSelected.categoria.length == 0) &&
      (this.filterSelected.grupo == undefined ||
        this.filterSelected.grupo.length == 0) &&
      (this.filterSelected.programa == undefined ||
        this.filterSelected.programa.length == 0) &&
      (this.filterSelected.empresa == undefined ||
        this.filterSelected.empresa.length == 0) &&
      (this.filterSelected.sentimento == undefined ||
        this.filterSelected.sentimento.length == 0) &&
      (this.filterSelected.subCategoria == undefined ||
        this.filterSelected.subCategoria.length == 0) &&
      (this.filterSelected.subGrupo == undefined ||
        this.filterSelected.subGrupo.length == 0) &&
      (this.filterSelected.autoridade == undefined ||
        this.filterSelected.autoridade.length == 0) &&
      (this.filterSelected.midia == undefined ||
        this.filterSelected.midia.length == 0) &&
      (this.filterSelected.veiculo == undefined ||
        this.filterSelected.veiculo.length == 0) &&
      (this.filterSelected.tipoInformacao == undefined ||
        this.filterSelected.tipoInformacao == 1) &&
      (this.filterForm.controls.titulo.value == '' ||
        this.filterForm.controls.titulo.value == null) &&
      this.filterForm.controls.dataInicial.value == this.thirtyDaysAgo &&
      this.filterForm.controls.dataFinal.value == this.todayDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  async limparFiltros() {
    this.filterSelected = {} as filterSelected;
    this.filterSelected.tipoInformacao = 1;
    this.minDate = <Date>{};
    this.maxDate = new Date();

    this.completeTipoInformacao.options.forEach((element) => {
      if (element.value == 'Notícias/Publicações') {
        element.select();
      } else {
        element.deselect();
      }
    });
    this.completeGrupo.options.forEach((element) => {
      element.deselect();
    });
    this.completePrograma.options.forEach((element) => {
      element.deselect();
    });
    this.completeEmpresa.options.forEach((element) => {
      element.deselect();
    });
    this.completeMidia.options.forEach((element) => {
      element.deselect();
    });
    this.completeVeiculo.options.forEach((element) => {
      element.deselect();
    });
    this.completeCategoria.options.forEach((element) => {
      element.deselect();
    });
    this.completeSentimento.options.forEach((element) => {
      element.deselect();
    });
    this.completeAutoridade.options.forEach((element) => {
      element.deselect();
    });
    if (this.permissaoSuperintendencia) {
      this.completeSubGrupo.options.forEach((element) => {
        element.deselect();
      });
    }
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.filterTrigger.categoria = '';
    this.filterTrigger.grupo = '';
    this.filterTrigger.programa = '';
    this.filterTrigger.empresa = '';
    this.filterTrigger.sentimento = '';
    this.filterTrigger.subCategoria = '';
    this.filterTrigger.subGrupo = '';
    this.filterTrigger.autoridade = '';
    this.filterTrigger.midia = '';
    this.filterTrigger.veiculo = '';

    this.filterForm.controls.dataInicial.setValue(this.thirtyDaysAgo);
    this.filterForm.controls.dataFinal.setValue(this.todayDate);
    this.filterForm.controls.titulo.setValue(null);
    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);

    this.disableFiltrosFunc();
    this.carregandoFiltros = true;

    this.grupos = [];
    this.empresas = [];
    this.midias = [];
    this.veiculos = [];
    this.categorias = [];
    this.sentimentos = [];
    this.autoridades = [];
    this.subGrupos = [];
    this.programas = [];

    this.showchips();
    let request = this.criarStringRequest();
    await Promise.all([
      this.listar_grupos(request),
      this.listar_empresas(request),
      // this.listar_midias(request),
      this.listar_veiculos(request),
      this.listar_categorias(request),
      this.listar_sentimentos(request),
      this.listar_autoridades(request),
      this.listar_programas(request),
      // this.listar_subGrupos(request),
      this.filtrar(),
    ]).then(() => {
      this.enableFiltrosFunc();
      this.carregandoFiltros = false;
    });
  }

  criarStringRequest(
    opcoes: {
      remove?: boolean;
      grupo?: boolean;
      empresa?: boolean;
      midia?: boolean;
      veiculo?: boolean;
      categoria?: boolean;
      sentimento?: boolean;
      autoridade?: boolean;
      subGrupo?: boolean;
      programa?: boolean;
    } = {}
  ) {
    const {
      remove = false,
      grupo = false,
      empresa = false,
      midia = false,
      veiculo = false,
      categoria = false,
      sentimento = false,
      autoridade = false,
      subGrupo = false,
      programa = false,
    } = opcoes;
    var request: string = '';
    let aux = '';
    this.haFiltroParaNuvem = false;

    if (
      categoria == false &&
      this.filterSelected.categoria != undefined &&
      this.filterSelected.categoria.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_categoria=`);
      for (let i = 0; i < this.filterSelected.categoria.length; i++) {
        request = request.concat(`${this.filterSelected.categoria[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      grupo == false &&
      this.filterSelected.grupo != undefined &&
      this.filterSelected.grupo.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_grupo=`);
      for (let i = 0; i < this.filterSelected.grupo.length; i++) {
        request = request.concat(`${this.filterSelected.grupo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      programa == false &&
      this.filterSelected.programa != undefined &&
      this.filterSelected.programa.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_programa=`);
      for (let i = 0; i < this.filterSelected.programa.length; i++) {
        request = request.concat(`${this.filterSelected.programa[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      empresa == false &&
      this.filterSelected.empresa != undefined &&
      this.filterSelected.empresa.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_empresa=`);
      for (let i = 0; i < this.filterSelected.empresa.length; i++) {
        request = request.concat(`${this.filterSelected.empresa[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      sentimento == false &&
      this.filterSelected.sentimento != undefined &&
      this.filterSelected.sentimento.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`sentimento=`);
      for (let i = 0; i < this.filterSelected.sentimento.length; i++) {
        request = request.concat(`${this.filterSelected.sentimento[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      autoridade == false &&
      this.filterSelected.autoridade != undefined &&
      this.filterSelected.autoridade.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_autoridade=`);
      for (let i = 0; i < this.filterSelected.autoridade.length; i++) {
        request = request.concat(`${this.filterSelected.autoridade[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      midia == false &&
      this.filterSelected.midia != undefined &&
      this.filterSelected.midia.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_midia=`);
      for (let i = 0; i < this.filterSelected.midia.length; i++) {
        request = request.concat(`${this.filterSelected.midia[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      veiculo == false &&
      this.filterSelected.veiculo != undefined &&
      this.filterSelected.veiculo.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_veiculo=`);
      for (let i = 0; i < this.filterSelected.veiculo.length; i++) {
        request = request.concat(`${this.filterSelected.veiculo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      subGrupo == false &&
      this.filterSelected.subCategoria != undefined &&
      this.filterSelected.subCategoria.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_subcategoria=`);
      for (let i = 0; i < this.filterSelected.subCategoria.length; i++) {
        request = request.concat(`${this.filterSelected.subCategoria[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (
      subGrupo == false &&
      this.filterSelected.subGrupo != undefined &&
      this.filterSelected.subGrupo.length > 0
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`id_sub_grupo=`);
      for (let i = 0; i < this.filterSelected.subGrupo.length; i++) {
        request = request.concat(`${this.filterSelected.subGrupo[i]},`);
      }
      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    if (this.filterSelected.tipoInformacao != undefined) {
      request = request.concat(`informacao=`);
      request = request.concat(`${this.filterSelected.tipoInformacao}`);
      request = request.concat(`&`);
    }

    if (
      this.filterSelected.titulo != undefined &&
      this.filterSelected.titulo != '' &&
      this.filterSelected.titulo != null
    ) {
      this.haFiltroParaNuvem = true;
      request = request.concat(`titulo=${this.filterSelected.titulo}&`);
    }
    if (this.filterSelected.dataInicial != undefined && remove == false)
      request = request.concat(
        `data_inicio=${this.filterSelected.dataInicial}&`
      );
    if (this.filterSelected.dataFinal != undefined && remove == false)
      request = request.concat(`data_fim=${this.filterSelected.dataFinal}&`);

    // Remover o último '&', se existir
    if (request.endsWith('&')) {
      request = request.slice(0, -1);
    }

    return request;
  }

  filtrar() {
    this.showchips();
    var request = this.criarStringRequest();
    this.dataNoticiaPorSentimento = null;
    // this.dataNoticiaPorMidia = null;
    //this.dataNoticiaPorVeiculo = null;
    // this.dataCentrimetragemPorMidia = null;
    this.dataSentimentoPorCategoria = null;
    this.dataNoticiaPorSubCategoria = null;
    //this.dataNoticiaPorPauta = null;
    this.sentimentoSentimentoPorTempo = null;
    this.dataSentimentoPorTempo = null;
    // this.midiaMidiaPorTempo = null;
    // this.dataMidiaPorTempo = null;
    this.dataNuvemDePalavras = null;
    this.dataNoticiaPorSentimentoDiario = null;
    this.carregandoTabela = true;
    this.carregandoLenth = true;
    this.carregandoSent = true;
    this.tabelaVazia = false;
    this.dataSentimentoPorOrgao = null;
    this.dataSentimentoPorPartido = null;
    this.dataSentimentoPorAutoridade = null;
    this.dataDetratores = null;
    this.dataEvangelizadores = null;
    this.dataPartidosDetratores = null;
    this.dataPartidosEvangelizadores = null;
    this.dataOrgaoPorTempo = null;
    this.orgaosOrgaoPorTempo = null;
    this.needFilterWordCloud = false;
    this.haNoticiasGauche = true;

    if (this.filterTrigger.grupo != undefined)
      this.grupospdf = this.filterTrigger.grupo;
    if (this.filterTrigger.empresa != undefined)
      this.empresaspdf = this.filterTrigger.empresa;

    try {
      Promise.all([
        // new Promise((resolve, reject) => {
        //   this.dashboardComunidadeService
        //     .partidoEvangelizadores(request)
        //     .subscribe({
        //       next: (value) => {
        //         this.dataPartidosEvangelizadores = value;
        //         resolve(true);
        //       },
        //       error: (error) => {
        //         this.recarregarPartidosEvangelizadores(1);
        //         reject(true);
        //       },
        //     });
        // }),
        // new Promise((resolve, reject) => {
        //   this.dashboardComunidadeService
        //     .partidoDetratores(request)
        //     .subscribe({
        //       next: (value) => {
        //         this.dataPartidosDetratores = value;
        //         resolve(true);
        //       },
        //       error: (error) => {
        //         this.recarregarPartidosDetratores(1);
        //         reject(true);
        //       },
        //     });
        // }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoDetratores(request)
            .subscribe({
              next: (value) => {
                this.dataDetratores = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoDetratores(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoEvangelizadores(request)
            .subscribe({
              next: (value) => {
                this.dataEvangelizadores = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoEvangelizadores(1);
                reject(true);
              },
            });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .autoridadesPorSentimento(request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorAutoridade = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarAutoridadesPorSentimento(1);
                reject(true);
              },
            });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .nuvemDePalavras(this.haFiltroParaNuvem, request)
            .subscribe({
              next: (value) => {
                this.dataNuvemDePalavras = value;
                resolve(true);
              },
              error: (error) => {
                if (error.status != 400) {
                  this.recarregarNuvemDePalavras(1);
                } else {
                  this.needFilterWordCloud = true;
                }
                reject(true);
              },
            });
        }).catch((error) => {
          // Trate o erro aqui conforme necessário
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .noticiaPorSentimento(request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSentimento = value;
                this.colorSentimento = this.setColorSentimento(
                  this.dataNoticiaPorSentimento
                );
                this.positiva = 0;
                this.negativa = 0;
                this.neutra = 0;
                value.forEach((element: any) => {
                  if (element[0] == 'POSITIVA') this.positiva = element[1];
                  if (element[0] == 'NEGATIVA') this.negativa = element[1];
                  if (element[0] == 'NEUTRA') this.neutra = element[1];
                });
                if (this.positiva + this.negativa > 0) {
                  this.dataNoticiaGauge =
                    this.positiva / (this.positiva + this.negativa);
                } else if (this.neutra > 0) {
                  this.haNoticiasGauche = false;
                }
                this.carregandoSent = false;
                resolve(true);
              },
              error: (error) => {
                this.recarregarNoticiaPorSentimento(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoPorOrgao(request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorOrgao = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoPorOrgao(1);
                reject(true);
              },
            });
        }),

        // new Promise((resolve, reject) => {
        //   this.dashboardComunidadeService
        //     .sentimentoPorPartido(request)
        //     .subscribe({
        //       next: (value) => {
        //         this.dataSentimentoPorPartido = value;

        //         resolve(true);
        //       },
        //       error: (error) => {
        //         this.recarregarSentimentoPorPartido(1);
        //         reject(true);
        //       },
        //     });
        // }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoPorCategoria(request)
            .subscribe({
              next: (value) => {
                this.dataSentimentoPorCategoria = value;
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoPorCategoria(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=1&per_page=${
                this.rowsPerPage
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.carregandoLenth = false;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.paginaAtual = 1;
                this.ultimaPagina = value._metadata.total_pages;

                resolve(true);
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
              },
              error: (error) => {
                reject(true);
                this.carregandoTabela = false;
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .sentimentoPorTempo(request)
            .subscribe({
              next: (value) => {
                this.sentimentoSentimentoPorTempo = value.sentimentos;
                this.dataSentimentoPorTempo = value.datas;
                this.colorSentimentoTempo = this.setColorSentimento(
                  this.sentimentoSentimentoPorTempo
                );
                resolve(true);
              },
              error: (error) => {
                this.recarregarSentimentoPorTempo(1);
                reject(true);
              },
            });
        }),
        new Promise((resolve, reject) => {
          this.dashboardComunidadeService.orgaosPorTempo(request).subscribe({
            next: (value) => {
              this.orgaosOrgaoPorTempo = value.orgaos;
              this.dataOrgaoPorTempo = value.datas;
              resolve(true);
            },
            error: (error) => {
              this.recarregarOrgaosPorTempo(1);
              reject(true);
            },
          });
        }),

        new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .noticiaPorSubCategoria(request)
            .subscribe({
              next: (value) => {
                this.dataNoticiaPorSubCategoria = value;

                resolve(true);
              },
              error: (error) => {
                this.recarregarNoticiaPorSubCategoria(1);
                reject(true);
              },
            });
        }),

        // new Promise((resolve, reject) => {
        //   this.dashboardComunidadeService
        //     .noticiaPorSentimentoDiario(request)
        //     .subscribe({
        //       next: (value) => {
        //         this.dataNoticiaPorSentimentoDiario = value;
        //         resolve(true);
        //       },
        //       error: (error) => {
        //         this.recarregarNoticiaPorSentimentoDiario(1);
        //         reject(true);
        //       },
        //     });
        // }),
      ]);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Houve um erro ao carregar os filtros',
        text: 'Por favor, tente novamente.',
        confirmButtonColor: 'orange',
      });
    }
  }

  addFilterTrigger(event: any, nome: keyof filterSelected) {
    if (event.isUserInput == false) return;
    if (event.source.selected == true) {
      if (this.filterTrigger[nome] == undefined) this.filterTrigger[nome] = '';
      this.filterTrigger[nome] = this.filterTrigger[nome].concat(
        `${event.source.value}, `
      );
    } else {
      this.filterTrigger[nome] = this.filterTrigger[nome].replace(
        `${event.source.value}, `,
        ''
      );
    }
  }

  async addFiltroSelect(
    event: any,
    nome: keyof filterSelected,
    id: any,
    categoriaPai: any = null
  ) {
    this.addFilterTrigger(event, nome);

    // SE O FILTRO NÃO EXISTIR, CRIAR
    if (this.filterSelected[nome] == undefined) this.filterSelected[nome] = [];

    // SE NÃO FOR UMA AÇÃO DO USUÁRIO, NÃO FAZER NADA
    if (event.isUserInput == false) return;

    // SE O FILTRO FOR O DE CATEGORIA
    if (nome == 'categoria') {
      this.haAlteracaoFiltroCategoria = true;
      // SE FOR UM EVENTO DE SELEÇÃO
      if (event.source.selected == true) {
        if (categoriaPai == null) {
          this.filterSelected.categoria.push(id);
        } else {
          if (this.filterSelected.subCategoria == undefined)
            this.filterSelected.subCategoria = [];
          // this.filterSelected.categoria = categoriaPai;
          this.filterSelected.subCategoria.push(id);
        }
      }

      // SE FOR UM EVENTO DE DESELEÇÃO
      else {
        if (categoriaPai == null) {
          this.filterSelected.categoria.splice(
            this.filterSelected.categoria.indexOf(id),
            1
          );
        } else {
          this.filterSelected.subCategoria.splice(
            this.filterSelected.subCategoria.indexOf(id),
            1
          );
        }
      }
    } else {
      if (nome == 'grupo') this.haAlteracaoFiltroGrupo = true;
      if (nome == 'empresa') this.haAlteracaoFiltroEmpresa = true;
      if (nome == 'autoridade') this.haAlteracaoFiltroAutoridade = true;
      if (nome == 'programa') this.haAlteracaoFiltroPrograma = true;
      if (nome == 'veiculo') this.haAlteracaoFiltroVeiculo = true;
      if (nome == 'midia') this.haAlteracaoFiltroMidia = true;
      if (nome == 'sentimento') this.haAlteracaoFiltroSentimento = true;
      if (nome == 'subGrupo') this.haAlteracaoFiltroSubGrupo = true;

      if (event.source.selected == true) {
        if (nome == 'tipoInformacao') {
          this.haAlteracaoFiltroInformacao = true;
          this.filterSelected.tipoInformacao = id;
          this.filterTrigger.tipoInformacao = event.source.value;
        } else this.filterSelected[nome].push(id);
      } else {
        this.filterSelected[nome].splice(
          this.filterSelected[nome].indexOf(id),
          1
        );
      }
    }
  }

  addFiltroInput(input: string) {
    if (
      this.filterForm.controls[input as keyof typeof this.filterForm.controls]
        .value == ''
    ) {
      this.filterForm.controls[
        input as keyof typeof this.filterForm.controls
      ].setValue(null);

      if (input == 'dataInicial') {
        this.minDate = <Date>{};
      }
      if (input == 'dataFinal') {
        this.maxDate = new Date();
      }

      this.filterSelected[input as keyof filterSelected] = undefined;
    } else {
      if (input == 'titulo') {
        this.filterSelected[input as keyof filterSelected] =
          this.filterForm.controls[
            input as keyof typeof this.filterForm.controls
          ].value;
      }
    }
  }

  async Interfiltragem(filtro: keyof typeof this.filterForm.controls) {
    this.filterForm.controls.searchTextboxCategoria.setValue(null);
    this.filterForm.controls.searchTextboxGrupo.setValue(null);
    this.filterForm.controls.searchTextboxPrograma.setValue(null);
    this.filterForm.controls.searchTextboxEmpresa.setValue(null);
    this.filterForm.controls.searchTextboxMidia.setValue(null);
    this.filterForm.controls.searchTextboxVeiculo.setValue(null);
    this.filterForm.controls.searchTextboxSentimento.setValue(null);
    this.filterForm.controls.searchTextboxAutoridade.setValue(null);
    this.filterForm.controls.searchTextboxSubGrupo.setValue(null);

    if (filtro == 'tipoInformacao' && this.haAlteracaoFiltroInformacao) {
      this.filterSelected.categoria = [];
      this.filterSelected.grupo = [];
      this.filterSelected.empresa = [];
      this.filterSelected.sentimento = [];
      this.filterSelected.subCategoria = [];
      this.filterSelected.subGrupo = [];
      this.filterSelected.programa = [];
      this.filterSelected.autoridade = [];
      this.filterSelected.midia = [];
      this.filterSelected.veiculo = [];

      this.completeGrupo.options.forEach((element) => {
        element.deselect();
      });
      this.completeEmpresa.options.forEach((element) => {
        element.deselect();
      });
      this.completeMidia.options.forEach((element) => {
        element.deselect();
      });
      this.completeVeiculo.options.forEach((element) => {
        element.deselect();
      });
      this.completeCategoria.options.forEach((element) => {
        element.deselect();
      });
      this.completeSentimento.options.forEach((element) => {
        element.deselect();
      });
      this.completeAutoridade.options.forEach((element) => {
        element.deselect();
      });
      this.completePrograma.options.forEach((element) => {
        element.deselect();
      });
      if (this.permissaoSuperintendencia) {
        this.completeSubGrupo.options.forEach((element) => {
          element.deselect();
        });
      }
      this.filterTrigger.categoria = '';
      this.filterTrigger.grupo = '';
      this.filterTrigger.empresa = '';
      this.filterTrigger.sentimento = '';
      this.filterTrigger.subCategoria = '';
      this.filterTrigger.subGrupo = '';
      this.filterTrigger.autoridade = '';
      this.filterTrigger.programa = '';
      this.filterTrigger.midia = '';
      this.filterTrigger.veiculo = '';

      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      //this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      var filtroParaSubGrupo: string;

      var filtroRequest = this.criarStringRequest({ remove: true });

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroInformacao = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'grupos' && this.haAlteracaoFiltroGrupo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      //this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        //this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroGrupo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'empresas' && this.haAlteracaoFiltroEmpresa) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      //this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        //this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroEmpresa = false;
      this.carregandoFiltros = false;
    }

    // if (filtro == 'midias' && this.haAlteracaoFiltroMidia) {
    //   this.disableFiltrosFunc();
    //   this.carregandoFiltros = true;

    //   //this.tiposInformacao = [];
    //   this.grupos = [];
    //   this.empresas = [];
    //   //this.midias = [];
    //   this.veiculos = [];
    //   this.categorias = [];
    //   this.sentimentos = [];
    //   this.autoridades = [];
    //   this.subGrupos = [];
    //   this.programas = [];

    //   await Promise.all([
    //     this.listar_grupos(this.criarStringRequest({ grupo: true })),
    //     this.listar_empresas(this.criarStringRequest({ empresa: true })),
    //     //this.listar_midias(this.criarStringRequest({ midia: true })),
    //     this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
    //     this.listar_categorias(this.criarStringRequest({ categoria: true })),
    //     this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
    //     this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
    //     // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
    //     this.listar_programas(this.criarStringRequest({ programa: true })),
    //   ]).catch((error) => {
    //     this.panelExpansion = false;
    //     this.erroCarregamentoFiltros = true;
    //     if (error.status != 401) {
    //       this.erroCarregamentoFiltros = true;
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Houve um erro ao carregar os filtros',
    //         text: 'Por favor, tente novamente.',
    //         confirmButtonColor: 'orange',
    //       });
    //     }
    //   });

    //   if (
    //     this.subGrupos.length > 0 &&
    //     this.filterForm.controls.grupos.value &&
    //     this.filterForm.controls.grupos.value.length > 0
    //   ) {
    //     this.filterForm.controls.subGrupos.enable();
    //   } else {
    //     this.filterForm.controls.subGrupos.disable();
    //   }

    //   this.enableFiltrosFunc();
    //   this.haAlteracaoFiltroMidia = false;
    //   this.carregandoFiltros = false;
    // }

    if (filtro == 'veiculos' && this.haAlteracaoFiltroVeiculo) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      //this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        //this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroVeiculo = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'categorias' && this.haAlteracaoFiltroCategoria) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      //this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        //this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroCategoria = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'sentimentos' && this.haAlteracaoFiltroSentimento) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      //this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        //this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroSentimento = false;
      this.carregandoFiltros = false;
    }

    if (filtro == 'autoridades' && this.haAlteracaoFiltroAutoridade) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      //this.autoridades = [];
      this.subGrupos = [];
      this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        //this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroAutoridade = false;
      this.carregandoFiltros = false;
    }

    // if (filtro == 'subGrupos' && this.haAlteracaoFiltroSubGrupo) {
    //   this.disableFiltrosFunc();
    //   this.carregandoFiltros = true;

    //   //this.tiposInformacao = [];
    //   this.grupos = [];
    //   this.empresas = [];
    //   this.midias = [];
    //   this.veiculos = [];
    //   this.categorias = [];
    //   this.sentimentos = [];
    //   this.autoridades = [];
    //   //this.subGrupos = [];
    //   this.programas = [];

    //   await Promise.all([
    //     this.listar_grupos(this.criarStringRequest({ grupo: true })),
    //     this.listar_empresas(this.criarStringRequest({ empresa: true })),
    //     // this.listar_midias(this.criarStringRequest({ midia: true })),
    //     this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
    //     this.listar_categorias(this.criarStringRequest({ categoria: true })),
    //     this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
    //     this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
    //     //this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
    //     this.listar_programas(this.criarStringRequest({ programa: true })),
    //   ]).catch((error) => {
    //     this.panelExpansion = false;
    //     this.erroCarregamentoFiltros = true;
    //     if (error.status != 401) {
    //       this.erroCarregamentoFiltros = true;
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Houve um erro ao carregar os filtros',
    //         text: 'Por favor, tente novamente.',
    //         confirmButtonColor: 'orange',
    //       });
    //     }
    //   });

    //   if (
    //     this.subGrupos.length > 0 &&
    //     this.filterForm.controls.grupos.value &&
    //     this.filterForm.controls.grupos.value.length > 0
    //   ) {
    //     this.filterForm.controls.subGrupos.enable();
    //   } else {
    //     this.filterForm.controls.subGrupos.disable();
    //   }

    //   this.enableFiltrosFunc();
    //   this.haAlteracaoFiltroSubGrupo = false;
    //   this.carregandoFiltros = false;
    // }

    if (filtro == 'programas' && this.haAlteracaoFiltroPrograma) {
      this.disableFiltrosFunc();
      this.carregandoFiltros = true;

      //this.tiposInformacao = [];
      this.grupos = [];
      this.empresas = [];
      this.midias = [];
      this.veiculos = [];
      this.categorias = [];
      this.sentimentos = [];
      this.autoridades = [];
      this.subGrupos = [];
      //this.programas = [];

      await Promise.all([
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        //this.listar_programas(this.criarStringRequest({ programa: true })),
      ]).catch((error) => {
        this.panelExpansion = false;
        this.erroCarregamentoFiltros = true;
        if (error.status != 401) {
          this.erroCarregamentoFiltros = true;
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao carregar os filtros',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      });

      if (
        this.subGrupos.length > 0 &&
        this.filterForm.controls.grupos.value &&
        this.filterForm.controls.grupos.value.length > 0
      ) {
        this.filterForm.controls.subGrupos.enable();
      } else {
        this.filterForm.controls.subGrupos.disable();
      }

      this.enableFiltrosFunc();
      this.haAlteracaoFiltroPrograma = false;
      this.carregandoFiltros = false;
    }

    this.cdr.detectChanges();
  }

  desabilitarFiltro(filterForm: FormControl) {
    filterForm.disable();
  }

  habilitarFiltro(filterForm: FormControl) {
    filterForm.enable();
  }

  // --------------------------------- Categoria -----------------------------------------------

  filterCategoria(name: string): categoria[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.categoria) {
      this.filterForm.controls.categorias.patchValue(
        this.filterTrigger.categoria.split(', ')
      );
    }

    let filteredList = this.categorias.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_categorias(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.categoriaService.listarCategorias(4, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.categorias.push(element);
          });
          this.filteredOptionsCategorias =
            this.filterForm.controls.searchTextboxCategoria.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterCategoria(name || '')) // Handle null value by providing an empty string
            );

          if (this.categorias.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.categorias);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.categorias);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Grupo----------------------------------------------

  filterGrupo(name: string): grupo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.grupo) {
      this.filterForm.controls.grupos.patchValue(
        this.filterTrigger.grupo.split(', ')
      );
    }

    let filteredList = this.grupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_grupos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.grupoService.listarGrupos(filtroRequest, 4).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.grupos.push(element);
          });
          this.filteredOptionsGrupos =
            this.filterForm.controls.searchTextboxGrupo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterGrupo(name || '')) // Handle null value by providing an empty string
            );

          if (this.grupos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.grupos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.grupos);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Programa----------------------------------------------

  filterPrograma(name: string): programa[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.programa) {
      this.filterForm.controls.programas.patchValue(
        this.filterTrigger.programa.split(', ')
      );
    }

    let filteredList = this.programas.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_programas(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.programaService.listarProgramas(4, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.programas.push(element);
          });
          this.filteredOptionsProgramas =
            this.filterForm.controls.searchTextboxPrograma.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterPrograma(name || '')) // Handle null value by providing an empty string
            );

          if (this.programas.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.programas);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.programas);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Empresa ----------------------------------------------

  filterEmpresa(name: string): empresa[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.empresa) {
      this.filterForm.controls.empresas.patchValue(
        this.filterTrigger.empresa.split(', ')
      );
    }

    let filteredList = this.empresas.filter((option) =>
      option.nomefantasia.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_empresas(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.empresaService.listarEmpresas(4, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.empresas.push(element);
          });
          this.filteredOptionsEmpresas =
            this.filterForm.controls.searchTextboxEmpresa.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterEmpresa(name || '')) // Handle null value by providing an empty string
            );

          if (this.empresas.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.empresas);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.empresas);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Sentimento ----------------------------------------------

  filterSentimento(name: string): sentimento[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.sentimento) {
      this.filterForm.controls.sentimentos.patchValue(
        this.filterTrigger.sentimento.split(', ')
      );
    }

    let filteredList = this.sentimentos.filter((option) =>
      option.sentimento.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_sentimentos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.sentimentoService.listarSentimentos(4, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.sentimentos.push(element);
          });
          this.filteredOptionsSentimentos =
            this.filterForm.controls.searchTextboxSentimento.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterSentimento(name || '')) // Handle null value by providing an empty string
            );

          if (this.sentimentos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.sentimentos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.sentimentos);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Autoridade ----------------------------------------------

  filterAutoridade(name: string): autoridade[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.autoridade) {
      this.filterForm.controls.autoridades.patchValue(
        this.filterTrigger.autoridade.split(', ')
      );
    }

    let filteredList = this.autoridades.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_autoridades(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.autoridadeService.listarAutoridades(4, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.autoridades.push(element);
          });
          this.filteredOptionsAutoridades =
            this.filterForm.controls.searchTextboxAutoridade.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterAutoridade(name || '')) // Handle null value by providing an empty string
            );

          if (this.autoridades.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.autoridades);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.autoridades);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Midia ----------------------------------------------

  // filterMidia(name: string): midia[] {
  //   const filterValue = name.toLowerCase();

  //   if (this.filterTrigger.midia) {
  //     this.filterForm.controls.midias.patchValue(
  //       this.filterTrigger.midia.split(', ')
  //     );
  //   }

  //   let filteredList = this.midias.filter((option) =>
  //     option.nome.toLowerCase().includes(filterValue)
  //   );
  //   return filteredList;
  // }

  // async listar_midias(filtroRequest: string = '') {
  //   return new Promise((resolve, reject) => {
  //     this.midiaService.listarMidias(4, filtroRequest).subscribe({
  //       next: (value) => {
  //         value.forEach((element) => {
  //           this.midias.push(element);
  //         });
  //         this.filteredOptionsMidias =
  //           this.filterForm.controls.searchTextboxMidia.valueChanges.pipe(
  //             startWith<string | null>(''),
  //             map((name: string | null) => this.filterMidia(name || '')) // Handle null value by providing an empty string
  //           );

  //         if (this.midias.length > 0) {
  //           this.habilitarFiltro(this.filterForm.controls.midias);
  //         } else {
  //           this.desabilitarFiltro(this.filterForm.controls.midias);
  //         }

  //         //this.valueChangesMidia();
  //         resolve(true);
  //       },
  //       error: (error) => {
  //         reject(error); // Rejeitar a Promise com o erro
  //       },
  //     });
  //   });
  // }

  // --------------------------------- Veiculo ----------------------------------------------

  filterVeiculo(name: string): veiculo[] {
    const filterValue = name.toLowerCase();

    if (this.filterTrigger.veiculo) {
      this.filterForm.controls.veiculos.patchValue(
        this.filterTrigger.veiculo.split(', ')
      );
    }

    let filteredList = this.veiculos.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_veiculos(filtroRequest: string = '') {
    return new Promise((resolve, reject) => {
      this.veiculoService.listarVeiculos(4, filtroRequest).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.veiculos.push(element);
          });
          this.filteredOptionsVeiculos =
            this.filterForm.controls.searchTextboxVeiculo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterVeiculo(name || '')) // Handle null value by providing an empty string
            );

          if (this.veiculos.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.veiculos);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.veiculos);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- SubGrupo -----------------------------------------------

  // filterSubGrupo(name: string): subGrupo[] {
  //   const filterValue = name.toLowerCase();

  //   if (this.filterTrigger.subGrupo) {
  //     this.filterForm.controls.subGrupos.patchValue(
  //       this.filterTrigger.subGrupo.split(', ')
  //     );
  //   }

  //   let filteredList = this.subGrupos.filter((option) =>
  //     option.descricao.toLowerCase().includes(filterValue)
  //   );
  //   return filteredList;
  // }

  // async listar_subGrupos(grupos: string = '') {
  //   if (!grupos.includes('id_grupo')) {
  //     return;
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       this.subGrupoService
  //         .listarSubGrupos(4, grupos, this.filterSelected.tipoInformacao)
  //         .subscribe({
  //           next: (value) => {
  //             value.forEach((element) => {
  //               this.subGrupos.push(element);
  //             });
  //             this.filteredOptionsSubGrupos =
  //               this.filterForm.controls.searchTextboxSubGrupo.valueChanges.pipe(
  //                 startWith<string | null>(''),
  //                 map((name: string | null) => this.filterSubGrupo(name || '')) // Handle null value by providing an empty string
  //               );

  //             if (this.subGrupos.length > 0) {
  //               this.habilitarFiltro(this.filterForm.controls.subGrupos);
  //             } else {
  //               this.desabilitarFiltro(this.filterForm.controls.subGrupos);
  //             }

  //             resolve(true);
  //           },
  //           error: (error) => {
  //             reject(error); // Rejeitar a Promise com o erro
  //           },
  //         });
  //     });
  //   }
  // }

  // --------------------------------- Tipo Informação -----------------------------------------------

  filterTipoInformacao(name: string): tipoInformacao[] {
    const filterValue = name.toLowerCase();

    // if (this.filterTrigger.tipoInformacao) {
    //   this.filterForm.controls.tipoInformacao.patchValue(
    //     this.filterTrigger.tipoInformacao.split(', ')
    //   );
    // }

    let filteredList = this.tiposInformacao.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_tipoInformacao() {
    return new Promise((resolve, reject) => {
      this.tipoInformacaoService.listarTiposInformacao(4).subscribe({
        next: (value) => {
          value.forEach((element) => {
            this.tiposInformacao.push(element);
          });
          this.filteredOptionsTiposInformacao =
            this.filterForm.controls.searchTextboxTipoInformacao.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) =>
                this.filterTipoInformacao(name || '')
              ) // Handle null value by providing an empty string
            );

          if (this.tiposInformacao.length > 0) {
            this.habilitarFiltro(this.filterForm.controls.tipoInformacao);
          } else {
            this.desabilitarFiltro(this.filterForm.controls.tipoInformacao);
          }

          resolve(true);
        },
        error: (error) => {
          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  // --------------------------------- Cores -----------------------------------------------
  setColorSentimento(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'POSITIVA') {
        colors.push(this.coresSentimento[0]);
      }
      if (element[0] === 'NEUTRA') {
        colors.push(this.coresSentimento[1]);
      }
      if (element[0] === 'NEGATIVA') {
        colors.push(this.coresSentimento[2]);
      }
    });
    return colors;
  }

  setColorMidia(matriz: any[][] | null): Color[] {
    const colors: Color[] = [];
    if (matriz == null) return colors;
    matriz.forEach((element) => {
      if (element[0] === 'Site') {
        colors.push('#9a7fd1');
      }
      if (element[0] === 'TV') {
        colors.push('#e28fab');
      }
      if (element[0] === 'Rádio') {
        colors.push('#b4897f');
      }
      if (element[0] === 'Impresso') {
        colors.push('#ffd6a3');
      }
    });
    return colors;
  }

  // --------------------------------- Tabela -----------------------------------------------

  async eventosTabela(event: PageEvent) {
    this.dataSource = new MatTableDataSource();

    if (event.pageSize != this.rowsPerPage) {
      this.carregandoTabela = true;
      this.tabelaVazia = false;
      try {
        await new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=${this.paginaAtual}&per_page=${
                event.pageSize
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.rowsPerPage = event.pageSize;
                this.ultimaPagina = value._metadata.total_pages;
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
                resolve(true);
              },
              error: (error) => {
                reject(true);
              },
            });
        });
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os filtros',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        Swal.close();
      }
    }

    if (event.previousPageIndex == event.pageIndex - 1) {
      this.carregandoTabela = true;
      this.tabelaVazia = false;
      try {
        await new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=${this.paginaAtual + 1}&per_page=${
                this.rowsPerPage
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.paginaAtual = this.paginaAtual + 1;
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
                resolve(true);
              },
              error: (error) => {
                reject(true);
              },
            });
        });
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os filtros',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        Swal.close();
      }
    } else if (event.previousPageIndex == event.pageIndex + 1) {
      this.carregandoTabela = true;
      this.tabelaVazia = false;
      try {
        await new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=${this.paginaAtual - 1}&per_page=${
                this.rowsPerPage
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.paginaAtual = this.paginaAtual - 1;
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
                resolve(true);
              },
              error: (error) => {
                reject(true);
              },
            });
        });
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os filtros',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        Swal.close();
      }
    } else if (
      event.previousPageIndex != undefined &&
      event.previousPageIndex > event.pageIndex
    ) {
      this.carregandoTabela = true;
      this.tabelaVazia = false;
      try {
        await new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=1&per_page=${
                this.rowsPerPage
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.paginaAtual = 1;
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
                resolve(true);
              },
              error: (error) => {
                reject(true);
              },
            });
        });
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os filtros',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        Swal.close();
      }
    } else if (
      event.previousPageIndex != undefined &&
      event.previousPageIndex < event.pageIndex
    ) {
      this.carregandoTabela = true;
      this.tabelaVazia = false;
      try {
        await new Promise((resolve, reject) => {
          this.dashboardComunidadeService
            .listarTabela(
              `4?page=${this.ultimaPagina}&per_page=${
                this.rowsPerPage
              }&${this.criarStringRequest()}`
            )
            .subscribe({
              next: (value) => {
                this.dataTabela = value.records;
                if (value.columns) this.displayedColumns = value.columns;
                this.length = value._metadata.total_count;
                this.dataSource = new MatTableDataSource(this.dataTabela);
                this.paginaAtual = this.ultimaPagina;
                this.carregandoTabela = false;
                if (value.columns.length == 0) this.tabelaVazia = true;
                resolve(true);
              },
              error: (error) => {
                reject(true);
              },
            });
        });
        Swal.close();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro ao carregar os filtros',
          text: 'Por favor, tente novamente.',
          confirmButtonColor: 'orange',
        });
        Swal.close();
      }
    }
    this.pageEvent = event;

    this.pageIndex = event.pageIndex;
  }

  openDialogAudio(
    conteudo: string,
    keywords: any[],
    midia: string,
    site: string,
    data: any,
    veiculo: string,
    titulo: string,
    arquivo: string,
    transcricao: string,
    programa: string,
    autoridade: string = '',
    partido: string = '',
    sentimento: string
  ) {
    const dialogRef = this.dialog.open(DialogAudioComponent, {
      width: '900px',

      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia,
        site: site,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        arquivo: arquivo,
        transcricao: transcricao,
        programa: programa,
        autoridade: autoridade,
        partido: partido,
        sentimento: sentimento,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogPdf(arquivo: string) {
    // Verifica se a midia é 'site' e se o link está definido

    // Abre o site em uma nova aba do navegador
    window.open(arquivo.substring(5), '_blank');
  }

  openDialogImg(
    conteudo: string,
    keywords: any[],
    midia: string,
    site: string,
    data: any,
    veiculo: string,
    titulo: string,
    arquivo: string,
    transcricao: string,
    programa: string,
    autoridade: string = '',
    partido: string = '',
    sentimento: string
  ) {
    const dialogRef = this.dialog.open(DialogImgComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia,
        site: site,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        arquivo: arquivo,
        transcricao: transcricao,
        programa: programa,
        autoridade: autoridade,
        partido: partido,
        sentimento: sentimento,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogVideo(
    conteudo: string,
    keywords: any[],
    midia: string,
    site: string,
    data: any,
    veiculo: string,
    titulo: string,
    arquivo: string,
    transcricao: string,
    programa: string,
    autoridade: string = '',
    partido: string = '',
    sentimento: string
  ) {
    const dialogRef = this.dialog.open(DialogVideoComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia,
        site: site,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        arquivo: arquivo,
        transcricao: transcricao,
        programa: programa,
        autoridade: autoridade,
        partido: partido,
        sentimento: sentimento,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogTranscricao(
    conteudo: string,
    keywords: any[],
    midia: string,
    site: string,
    data: any,
    veiculo: string,
    titulo: string,
    transcricao: string,
    programa: string,
    autoridade: string = '',
    partido: string = '',
    sentimento: string
  ) {
    const dialogRef = this.dialog.open(DialogTranscricaoComponent, {
      width: '900px',
      data: {
        conteudo: conteudo,
        keywords: keywords,
        midia: midia,
        site: site,
        data: data,
        veiculo: veiculo,
        titulo: titulo,
        transcricao: transcricao,
        programa: programa,
        sentimento: sentimento,
        autoridade: autoridade,
        partido: partido,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  isAudioFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('audio: ');
  }

  isPdfFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('pdf: ');
  }

  isImageFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('image: ');
  }

  isVideoFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('video: ');
  }

  isTextFile(fileName: string): boolean {
    if (fileName == null) return false;
    return fileName.toLowerCase().startsWith('texto: ');
  }

  isArqNull(fileName: string): boolean {
    if (fileName == null) return true;
    return false;
  }
  OpenLinkInNewTab(url: string) {
    window.open(url, '_blank');
  }

  isArqAndLinkNull(fileName: string, link: string): boolean {
    if (fileName == null && link == null) return true;
    return false;
  }

  // --------------------------------- PDF Charts -----------------------------------------------
  async gerarPDF() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          let doc: jsPDF = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [1020, 640],
          });

          // Adicionar Capa
          const capaImage = '../../assets/logo_clipei_novo.png'; // Caminho para a imagem da capa
          const tituloRelatorio = 'RELATÓRIO DE COMUNIDADES'; // Título do Relatório
          let Grupos = '';

          if (this.empresaspdf != undefined && this.empresaspdf != '') {
            Grupos = this.empresaspdf.slice(0, -2);
          } else if (this.grupospdf != undefined && this.grupospdf != '') {
            Grupos = this.grupospdf.slice(0, -2);
          } else {
            this.filteredOptionsGrupos
              ?.pipe(
                map((grupos: grupo[]) => {
                  return grupos.map((element: grupo) => element.descricao);
                })
              )
              .subscribe((descricaoGrupos: string[]) => {
                Grupos = descricaoGrupos.join(', ');
              });
          }

          const datafinal = this.moment(
            this.filterForm.controls.dataFinal.value
          ).toDate();
          const datainicial = this.moment(
            this.filterForm.controls.dataInicial.value
          ).toDate();
          const dataRelatorio = `${datainicial.getDate()}.${
            this.Meses[datainicial.getMonth() + 1]
          }.${datainicial.getFullYear()} A ${datafinal.getDate()}.${
            this.Meses[datafinal.getMonth() + 1]
          }.${datafinal.getFullYear()}`;
          // Data do Relatório

          doc.addImage(capaImage, 'PNG', 50, 200, 400, 176);

          // Adicionar título do relatório
          doc.setFont('helvetica');
          doc.setFontSize(40);

          doc.text(tituloRelatorio, doc.internal.pageSize.width - 30, 400, {
            align: 'right',
          });

          doc.setFont('helvetica', 'bold');
          doc.setFontSize(30);
          doc.setTextColor('#000000'); // cor preta
          // Dividindo o texto em várias linhas
          const textoDividido = doc.splitTextToSize(Grupos, 700);
          let alturagrupo = 410;
          // Desenhando cada linha individualmente
          for (let i = 0; i < textoDividido.length; i++) {
            alturagrupo += 25;
            doc.text(
              textoDividido[i],
              doc.internal.pageSize.width - 30,
              alturagrupo,
              { align: 'right' }
            ); // Incremento de 25 para o espaçamento entre as linhas
          }

          // Adicionar data
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(30);
          doc.setTextColor('#000000'); // cor preta
          doc.text(
            dataRelatorio,
            doc.internal.pageSize.width - 30,
            alturagrupo + 35,
            {
              align: 'right',
            }
          );

          const chartsArray = this.charts.toArray();

          doc.setFont('helvetica', 'normal');
          doc.setFontSize(15);

          for (let index = 0; index < this.charts.length; index++) {
            // Convert QueryList to an array
            await chartsArray[index].imageOutput();
            if (
              index == 1 &&
              this.haNoticiasGauche &&
              !(
                !this.dataNoticiaPorSentimento && !this.erroNoticiaPorSentimento
              )
            ) {
              doc.addImage(
                '../../assets/info_gauge_sessoes.png',
                'PNG',
                160,
                500,
                700,
                123
              );
            }

            doc.addPage();

            if (doc) {
              doc.addImage(
                this.chartDataPDF.dataURL,
                'PNG',
                10,
                10,
                this.chartDataPDF.imgWidth,
                this.chartDataPDF.imgHeight
              );
              doc.addImage(
                '../../assets/logo_clipei.png',
                'PNG',
                895,
                585,
                100,
                50
              );
            }
          }

          setTimeout(() => {
            if (doc) {
              doc.save('Relatorio_Comunidades.pdf');
            }
            Swal.close();
          }, 2000);
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Houve um erro ao gerar o PDF',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
        }
      },
    });
  }

  handleChartImage(chartImageObj: {
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }): void {
    const { dataURL, imgWidth, imgHeight } = chartImageObj;
    this.chartDataPDF.dataURL = dataURL;
    this.chartDataPDF.imgWidth = imgWidth;
    this.chartDataPDF.imgHeight = imgHeight;

    // Agora você pode usar esses dados como preferir, talvez adicionar a um PDF
  }

  // --------------------------------- Excel -----------------------------------------------

  async gerarCSV() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const request = this.criarStringRequest();
          await this.dashboardComunidadeService.gerarCSV(request);

          Swal.close();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'houve um erro ao gerar o pdf',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
          Swal.close();
        }
      },
    });
  }
  gerarPlanilha() {
    Swal.fire({
      background: '#ffffff00',
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          const request = this.criarStringRequest();
          await this.dashboardComunidadeService.gerarPlanilha(request);

          Swal.close();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'houve um erro ao gerar o pdf',
            text: 'Por favor, tente novamente.',
            confirmButtonColor: 'orange',
          });
          Swal.close();
        }
      },
    });
  }

  cancelarCarregamentoFiltros() {
    this.tipoInformacaoService.cancelarChamada();
    this.grupoService.cancelarChamada();
    this.empresaService.cancelarChamada();
    this.midiaService.cancelarChamada();
    this.veiculoService.cancelarChamada();
    this.categoriaService.cancelarChamada();
    this.sentimentoService.cancelarChamada();
    this.autoridadeService.cancelarChamada();
    this.subGrupoService.cancelarChamada();
    this.programaService.cancelarChamada();
    this.pautaService.cancelarChamada();
    this.programaService.cancelarChamada();
  }

  async recarregarFiltros() {
    this.panelExpansion = true;
    this.cancelarCarregamentoFiltros();

    this.erroCarregamentoFiltros = false;
    this.carregandoFiltros = true;
    this.carregandoFiltrosInit = true;

    this.filterSelected = {} as filterSelected;
    this.filterSelected.tipoInformacao = 1;
    this.minDate = <Date>{};
    this.maxDate = new Date();

    this.completeTipoInformacao.options.forEach((element) => {
      if (element.value == 'Notícias/Publicações') {
        element.select();
      } else {
        element.deselect();
      }
    });
    this.completeGrupo.options.forEach((element) => {
      element.deselect();
    });
    this.completePrograma.options.forEach((element) => {
      element.deselect();
    });
    this.completeEmpresa.options.forEach((element) => {
      element.deselect();
    });
    this.completeMidia.options.forEach((element) => {
      element.deselect();
    });
    this.completeVeiculo.options.forEach((element) => {
      element.deselect();
    });
    this.completeCategoria.options.forEach((element) => {
      element.deselect();
    });
    this.completeSentimento.options.forEach((element) => {
      element.deselect();
    });
    this.completeAutoridade.options.forEach((element) => {
      element.deselect();
    });
    if (this.permissaoSuperintendencia) {
      this.completeSubGrupo.options.forEach((element) => {
        element.deselect();
      });
    }
    this.filterTrigger.tipoInformacao = 'Notícias/Publicações';
    this.filterTrigger.categoria = '';
    this.filterTrigger.grupo = '';
    this.filterTrigger.programa = '';
    this.filterTrigger.empresa = '';
    this.filterTrigger.sentimento = '';
    this.filterTrigger.subCategoria = '';
    this.filterTrigger.subGrupo = '';
    this.filterTrigger.autoridade = '';
    this.filterTrigger.midia = '';
    this.filterTrigger.veiculo = '';

    this.filterForm.controls.dataInicial.setValue(this.thirtyDaysAgo);
    this.filterForm.controls.dataFinal.setValue(this.todayDate);
    this.filterForm.controls.titulo.setValue(null);
    this.setDate(this.filterForm.controls.dataInicial.value, true);
    this.setDate(this.filterForm.controls.dataFinal.value, true);

    this.tiposInformacao = [];
    this.grupos = [];
    this.empresas = [];
    this.midias = [];
    this.veiculos = [];
    this.categorias = [];
    this.sentimentos = [];
    this.autoridades = [];
    this.subGrupos = [];
    this.programas = [];

    try {
      await Promise.all([
        this.listar_tipoInformacao(),
        this.listar_grupos(this.criarStringRequest({ grupo: true })),
        this.listar_empresas(this.criarStringRequest({ empresa: true })),
        // this.listar_midias(this.criarStringRequest({ midia: true })),
        this.listar_veiculos(this.criarStringRequest({ veiculo: true })),
        this.listar_categorias(this.criarStringRequest({ categoria: true })),
        this.listar_sentimentos(this.criarStringRequest({ sentimento: true })),
        this.listar_autoridades(this.criarStringRequest({ autoridade: true })),
        // this.listar_subGrupos(this.criarStringRequest({ subGrupo: true })),
        this.listar_programas(this.criarStringRequest({ programa: true })),
      ]);
      this.carregandoFiltros = false;
      this.carregandoFiltrosInit = false;
      this.onResize();

      this.completeTipoInformacao.options.forEach((element_10) => {
        if (element_10.value == 'Notícias/Publicações') {
          element_10.select();
        }
      });
    } catch (error) {
      this.erroCarregamentoFiltros = true;
      Swal.fire({
        icon: 'error',
        title: 'Houve um erro ao carregar os filtros',
        text: 'Por favor, tente novamente.',
        confirmButtonColor: 'orange',
      });
    }
  }

  disableFiltrosFunc() {
    this.filterForm.controls.tipoInformacao.disable();
    this.filterForm.controls.categorias.disable();
    this.filterForm.controls.grupos.disable();
    this.filterForm.controls.empresas.disable();
    this.filterForm.controls.sentimentos.disable();
    this.filterForm.controls.autoridades.disable();
    this.filterForm.controls.programas.disable();
    this.filterForm.controls.midias.disable();
    this.filterForm.controls.veiculos.disable();
    this.filterForm.controls.subGrupos.disable();
    this.filterForm.controls.titulo.disable();
    this.disableFiltros = true;
  }

  enableFiltrosFunc() {
    if (this.categorias.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.categorias);
    }
    if (this.grupos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.grupos);
    }
    if (this.empresas.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.empresas);
    }
    if (this.sentimentos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.sentimentos);
    }
    if (this.autoridades.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.autoridades);
    }
    if (this.programas.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.programas);
    }
    if (this.midias.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.midias);
    }
    if (this.veiculos.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.veiculos);
    }
    if (this.tiposInformacao.length > 0) {
      this.habilitarFiltro(this.filterForm.controls.tipoInformacao);
    }
    //this.filterForm.controls.subGrupos.enable();
    this.filterForm.controls.titulo.enable();
    this.disableFiltros = false;
  }

  recarregarSentimentoDetratores(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .sentimentoDetratores(request)
          .subscribe({
            next: (value) => {
              this.dataDetratores = value;
              resolve(true);
            },
            error: (error) => {
              reject(true);
            },
          });
      });
    } else {
      this.erroSentimentoDetratores = true;
    }
  }

  recarregarSentimentoEvangelizadores(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .sentimentoEvangelizadores(request)
          .subscribe({
            next: (value) => {
              this.dataEvangelizadores = value;
              resolve(true);
            },
            error: (error) => {
              reject(true);
            },
          });
      });
    } else {
      this.erroSentimentoEvangelizadores = true;
    }
  }

  recarregarAutoridadesPorSentimento(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .autoridadesPorSentimento(request)
          .subscribe({
            next: (value) => {
              this.dataSentimentoPorAutoridade = value;
              resolve(true);
            },
            error: (error) => {
              reject(true);
            },
          });
      });
    } else {
      this.erroAutoridadesPorSentimento = true;
    }
  }

  recarregarOrgaosPorTempo(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService.orgaosPorTempo(request).subscribe({
          next: (value) => {
            this.orgaosOrgaoPorTempo = value.orgaos;
            this.dataOrgaoPorTempo = value.datas;
            resolve(true);
          },
          error: (error) => {
            reject(true);
          },
        });
      });
    } else {
      this.erroOrgaosPorTempo = true;
    }
  }

  recarregarNoticiaPorSentimento(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .noticiaPorSentimento(request)
          .subscribe({
            next: (value) => {
              this.dataNoticiaPorSentimento = value;
              this.colorSentimento = this.setColorSentimento(
                this.dataNoticiaPorSentimento
              );
              this.positiva = 0;
              this.negativa = 0;
              this.neutra = 0;
              value.forEach((element: any) => {
                if (element[0] == 'POSITIVA') this.positiva = element[1];
                if (element[0] == 'NEGATIVA') this.negativa = element[1];
                if (element[0] == 'NEUTRA') this.neutra = element[1];
              });
              if (this.positiva + this.negativa > 0) {
                this.dataNoticiaGauge =
                  this.positiva / (this.positiva + this.negativa);
              } else if (this.neutra > 0) {
                this.haNoticiasGauche = false;
              }
              this.carregandoSent = false;
              resolve(true);
            },
            error: (error) => {
              reject(true);
            },
          });
      });
    } else {
      this.erroNoticiaPorSentimento = true;
    }
  }

  recarregarSentimentoPorOrgao(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService.sentimentoPorOrgao(request).subscribe({
          next: (value) => {
            this.dataSentimentoPorOrgao = value;
            resolve(true);
          },
          error: (error) => {
            reject(true);
          },
        });
      });
    } else {
      this.erroSentimentoPorOrgao = true;
    }
  }

  recarregarSentimentoPorCategoria(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .sentimentoPorCategoria(request)
          .subscribe({
            next: (value) => {
              this.dataSentimentoPorCategoria = value;
              resolve(true);
            },
            error: (error) => {
              reject(true);
            },
          });
      });
    } else {
      this.erroSentimentoPorCategoria = true;
    }
  }

  recarregarTabela(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .listarTabela(
            `4?page=1&per_page=${this.rowsPerPage}&${this.criarStringRequest()}`
          )
          .subscribe({
            next: (value) => {
              this.dataTabela = value.records;

              if (value.columns) this.displayedColumns = value.columns;
              this.length = value._metadata.total_count;
              this.carregandoLenth = false;
              this.dataSource = new MatTableDataSource(this.dataTabela);
              this.ultimaPagina = value._metadata.total_pages;

              resolve(true);
              this.carregandoTabela = false;
              if (value.columns.length == 0) this.tabelaVazia = true;
            },
            error: (error) => {
              reject(true);
              this.carregandoTabela = false;
            },
          });
      });
    } else {
      this.erroTabela = true;
    }
  }

  recarregarSentimentoPorTempo(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService.sentimentoPorTempo(request).subscribe({
          next: (value) => {
            this.sentimentoSentimentoPorTempo = value.sentimentos;
            this.dataSentimentoPorTempo = value.datas;
            this.colorSentimentoTempo = this.setColorSentimento(
              this.sentimentoSentimentoPorTempo
            );
            resolve(true);
          },
          error: (error) => {
            reject(true);
          },
        });
      });
    } else {
      this.erroSentimentoPorTempo = true;
    }
  }

  recarregarNoticiaPorSubCategoria(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .noticiaPorSubCategoria(request)
          .subscribe({
            next: (value) => {
              this.dataNoticiaPorSubCategoria = value;

              resolve(true);
            },
            error: (error) => {
              reject(true);
            },
          });
      });
    } else {
      this.erroNoticiaPorSubCategoria = true;
    }
  }

  recarregarNuvemDePalavras(count: number) {
    if (count < 5) {
      var request = this.criarStringRequest();
      new Promise((resolve, reject) => {
        this.dashboardComunidadeService
          .nuvemDePalavras(this.haFiltroParaNuvem, request)
          .subscribe({
            next: (value) => {
              this.dataNuvemDePalavras = value;
              resolve(true);
            },
            error: (error) => {
              if (error.status != 400) {
                this.recarregarNuvemDePalavras(count + 1);
              } else {
                this.needFilterWordCloud = true;
              }
              reject(true);
            },
          });
      }).catch((error) => {
        // Trate o erro aqui conforme necessário
      });
    } else {
      this.erroNuvemDePalavras = true;
    }
  }

  FormatarNuvemDePalavrasLargura() {
    const excludeKeys = [
      'Lideranças Evangelizadoras (Top 15)',
      "Lideranças Detratoras (Top 15)')",
      'Notícias por Categoria (Top 15)',
      'Notícias por Subcategoria (Top 15)',

      'Notícias por Classificação (Linhas e Barras)',
      'Notícias por Encontro (Linhas e Barras) (Top 5)',
    ];

    const trueCount = this.permissionCharts.filter(
      (key) => !excludeKeys.includes(key)
    ).length;

    // Verificar se o número de `true` é par ou ímpar
    const isEven = trueCount % 2 === 0;

    if (isEven) {
      return 1;
    } else {
      return 2;
    }
  }
}
