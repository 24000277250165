<div class="dialog-container">

  <p class="dialog-title">Visualização de Arquivo</p>
  <div class="divider"></div>
  <div class="dialog-content">

    <p><strong>Data:</strong> {{data.data}}</p>

    <div *ngIf="data.conteudo != 'legislativo'">
      <p><strong>Veículo:</strong> {{data.veiculo}}</p>
      <p><strong>Programa:</strong> {{data.programa}}</p>
    </div>

    <div *ngIf="data.conteudo == 'legislativo'">
      <p><strong> Órgão:</strong> {{data.veiculo}}</p>
      <p><strong>Sessão:</strong> {{data.programa}}</p>
    </div>

    <div *ngIf="data.conteudo == 'legislativo'">
      <p><strong>Autoridade:</strong> {{data.autoridade}}</p>
      <p><strong>Partido:</strong> {{data.partido}}</p>
    </div>

    <p><strong>Título:</strong> {{data.titulo}}</p>
    <p><strong>Classificação:</strong> {{data.sentimento}}</p>


    <button mat-raised-button *ngIf="data.midia == 'Site'" color="primary" class="button-login" (click)="Acessar()">Acessar site</button>

    <hr class="divider-line">
    <img src={{img}} alt="Sua Imagem" style="width: 100%;">

    <div class="espacamento"></div>
    <div *ngIf=" data.transcricao != null && data.transcricao != ''">
      <strong>Transcricao:</strong>
      <div class="transcription" [innerHTML]="formatarTexto()"></div>
    </div>
  </div>
  <button mat-raised-button color="primary" class="button-login" (click)="closeDialog()">Fechar</button>
</div>
