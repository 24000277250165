

<div class="dialog-container">

  <p class="dialog-title">Visualização de Arquivo</p>
  <div class="divider"></div>
  <div class="dialog-content">

    <div *ngIf="data.conteudo == 'imprensa'">
    </div>

    <div *ngIf="data.conteudo == 'juridico'">
    </div>

    <div *ngIf="data.conteudo == 'legislativo'">
    </div>


    <p><strong>Veículo:</strong> {{data.veiculo}}</p>
    <p><strong>Data:</strong> {{data.data}}</p>
    <p><strong>Título:</strong> {{data.titulo}}</p>
    <p><strong>Arquivo:</strong> {{data.arquivo}}</p>
    <button mat-raised-button *ngIf="data.midia == 'Site'" color="primary" class="button-login"
      (click)="Acessar()">Acessar site</button>

    <hr class="divider-line">


    <div class="pdf-container">
      <a [href]="pdf" rel="noopener noreferrer">Abrir PDF</a>
    </div>
  </div>

  <!-- <div class="pdf-container">
    <pdf-viewer [src]="pdf" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1"
      [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
      [autoresize]="true" [show-borders]="false" style="width: 100%; height: 420px;"></pdf-viewer>
  </div> -->

  <button mat-raised-button color="primary" class="button-login" (click)="closeDialog()">Fechar</button>
</div>
