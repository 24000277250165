import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrocarSenhaService } from 'src/app/core/services/trocar-senha.service';
import { UserService } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-solicitar-redefinicao-senha',
  templateUrl: './solicitar-redefinicao-senha.component.html',
  styleUrls: ['./solicitar-redefinicao-senha.component.scss'],
})
export class SolicitarRedefinicaoSenhaComponent implements OnInit {
  loginOuEmailInvalido = false;
  senhaIgualAnterior = false;
  token: string | null = '';
  envioLink = false;
  desativaBotao = true;

  emailForm = new FormGroup({
    login: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  initialFormValues: Partial<{
    login: string | null;
    email: string | null
  }> = {
    login: '',
    email: '',
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private trocarSenhaService: TrocarSenhaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    // Monitora mudanças no formulário
    this.emailForm.valueChanges.subscribe((values) => {
      this.desativaBotao = !this.isFormChanged(values);
    });
  }

  deslogar() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  Administrativo() {
    this.router.navigate(['admin/']);
  }

  TrocarSenha() {
    if (!this.envioLink) {
      this.envioLink = true;
      this.loginOuEmailInvalido = false;
      this.initialFormValues = this.emailForm.value;
      this.trocarSenhaService
        .enviarEmail(
          this.emailForm.controls.login.value ?? '',
          this.emailForm.controls.email.value ?? ''
        )
        .subscribe({
          next: (response) => {
            if (response.status == 204) {
              this.envioLink = false;
              Swal.fire({
                icon: 'success',
                title: 'E-mail enviado com sucesso!',
                text: 'Verifique sua caixa de entrada para redefinir sua senha.',
                showConfirmButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
              }).then(() => {
                this.Voltar();
              });
            }
          },
          error: () => {
            this.envioLink = false;
            this.desativaBotao = true;
            this.loginOuEmailInvalido = true;
          },
        });
    }
  }

  isFormChanged(
    currentValues: Partial<{
      login: string | null;
      email: string | null;
    }>
  ): boolean {
    return (
      JSON.stringify(this.initialFormValues) !== JSON.stringify(currentValues)
    );
  }

  Voltar() {
    this.router.navigate(['login']);
  }
}
