import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AutoridadeService } from 'src/app/core/services/autoridade.service';
import { CategoriaService } from 'src/app/core/services/categoria.service';
import { DashboardComunidadeService } from 'src/app/core/services/dashboard-comunidade.service';
import { DashboardImprensaService } from 'src/app/core/services/dashboard-imprensa.service';
import { DashboardJuridicoService } from 'src/app/core/services/dashboard-juridico.service';
import { DashboardLegislativoService } from 'src/app/core/services/dashboard-legislativo.service';
import { EmpresaService } from 'src/app/core/services/empresa.service';
import { GrupoService } from 'src/app/core/services/grupo.service';
import { MidiaService } from 'src/app/core/services/midia.service';
import { PautaService } from 'src/app/core/services/pauta.service';
import { ProgramaService } from 'src/app/core/services/programa.service';
import { SentimentoService } from 'src/app/core/services/sentimento.service';
import { SubGrupoService } from 'src/app/core/services/subgrupo.service';
import { TipoInformacaoService } from 'src/app/core/services/tipo-informacao.service';
import { TrocarSenhaService } from 'src/app/core/services/trocar-senha.service';
import { UserService } from 'src/app/core/services/user.service';
import { VeiculoService } from 'src/app/core/services/veiculo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.scss'],
})
export class TrocarSenhaComponent implements OnInit {
  usuario = localStorage.getItem('nome');
  avatar = localStorage.getItem('avatar');
  admin: boolean = false;
  hidePassword = true;
  hideNewPassword = true;
  hideConfirmNewPassword = true;
  novaSenhaCombina = false;
  senhaIncorreta = false;
  desabilitaBotao = true;
  isLoading = false;
  click = false;

  passwordForm = new FormGroup({
    senha: new FormControl('', [Validators.required]),
    novaSenha: new FormControl('', [Validators.required]),
    confirmSenha: new FormControl('', [Validators.required]),
  });

  initialFormValues: Partial<{
    senha: string | null;
    novaSenha: string | null;
    confirmSenha: string | null;
  }> = {
    senha: '',
    novaSenha: '',
    confirmSenha: '',
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private location: Location,
    private trocarSenhaService: TrocarSenhaService,

    // dashboards
    private dashboardComunidadeService: DashboardComunidadeService,
    private dashboardImprensaService: DashboardImprensaService,
    private dashboardJuridicoService: DashboardJuridicoService,
    private dashboardLegislativoService: DashboardLegislativoService,

    // filtros
    private grupoService: GrupoService,
    private tipoInformacaoService: TipoInformacaoService,
    private empresaService: EmpresaService,
    private midiaService: MidiaService,
    private veiculoService: VeiculoService,
    private categoriaService: CategoriaService,
    private sentimentoService: SentimentoService,
    private autoridadeService: AutoridadeService,
    private subGrupoService: SubGrupoService,
    private programaService: ProgramaService,
    private pautaService: PautaService
  ) {}

  ngOnInit(): void {
    this.admin = this.userService.isAdmin();
    this.verificaSenha();
    // Monitora mudanças no formulário
    this.passwordForm.valueChanges.subscribe((values) => {
      this.desabilitaBotao = !this.isFormChanged(values);
    });

    this.dashboardComunidadeService.cancelarChamada();
    this.dashboardImprensaService.cancelarChamada();
    this.dashboardJuridicoService.cancelarChamada();
    this.dashboardLegislativoService.cancelarChamada();
    this.cancelarCarregamentoFiltros();
  }

  visibilidadeSenha(visibilidade: string) {
    if (visibilidade === 'senha') {
      this.hidePassword = !this.hidePassword;
    } else if (visibilidade === 'novaSenha') {
      this.hideNewPassword = !this.hideNewPassword;
    } else if (visibilidade === 'confirmSenha') {
      this.hideConfirmNewPassword = !this.hideConfirmNewPassword;
    }
  }

  deslogar() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  Administrativo() {
    this.router.navigate(['admin/']);
  }

  TrocarSenha() {
    this.isLoading = true;
    this.senhaIncorreta = false;
    this.initialFormValues = this.passwordForm.value;
    Swal.fire({
      title: 'Redefinindo senha...',
      html: 'Aguarde enquanto verificamos as informações.',
      showConfirmButton: false,
      confirmButtonColor: 'orange',
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: async () => {
        Swal.showLoading();

        this.trocarSenhaService
          .trocarSenha(
            this.passwordForm.controls.senha.value ?? '',
            this.passwordForm.controls.novaSenha.value ?? ''
          )
          .subscribe({
            next: (response) => {
              this.isLoading = false;
              if (response.status == 204) {
                Swal.fire({
                  icon: 'success',
                  title: 'Troca de senha bem sucedida!',
                  text: 'Você será redirecionado para a página anterior',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  confirmButtonColor: 'green',
                }).then(() => {
                  this.Voltar();
                });
              }
            },
            error: (error: any) => {
              this.isLoading = false;
              this.desabilitaBotao = true;
              if (error.message.includes('Senha atual incorreta.')) {
                this.senhaIncorreta = true;
                Swal.fire({
                  icon: 'error',
                  title: 'Erro ao trocar senha',
                  text: 'Senha atual incorreta.',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  confirmButtonColor: 'red',
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Erro ao trocar senha',
                  text: 'Tente novamente mais tarde.',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  confirmButtonColor: 'red',
                });
              }
            },
          });
      },
    });
  }

  Paineis() {
    this.router.navigate(['home']);
  }

  Voltar() {
    this.location.back();
  }

  isFormChanged(
    currentValues: Partial<{
      senha: string | null;
      novaSenha: string | null;
      confirmSenha: string | null;
    }>
  ): boolean {
    return (
      JSON.stringify(this.initialFormValues) !== JSON.stringify(currentValues)
    );
  }

  verificaSenha() {
    this.passwordForm.controls.novaSenha.valueChanges.subscribe(() => {
      this.click = true;
      if (
        this.passwordForm.controls.novaSenha.value ===
          this.passwordForm.controls.confirmSenha.value &&
        this.passwordForm.controls.novaSenha.value != '' &&
        this.passwordForm.controls.confirmSenha.value != ''
      ) {
        this.novaSenhaCombina = true;
      } else {
        this.novaSenhaCombina = false;
        this.desabilitaBotao = true;
      }
    });

    this.passwordForm.controls.confirmSenha.valueChanges.subscribe(() => {
      if (
        this.passwordForm.controls.novaSenha.value ===
          this.passwordForm.controls.confirmSenha.value &&
        this.passwordForm.controls.novaSenha.value != '' &&
        this.passwordForm.controls.confirmSenha.value != ''
      ) {
        this.novaSenhaCombina = true;
      } else {
        this.novaSenhaCombina = false;
        this.desabilitaBotao = true;
      }
    });
  }

  // --------------------------------- Cancelamento de promisses ----------------------------------------------
  cancelarCarregamentoFiltros() {
    this.tipoInformacaoService.cancelarChamada();
    this.grupoService.cancelarChamada();
    this.empresaService.cancelarChamada();
    this.midiaService.cancelarChamada();
    this.veiculoService.cancelarChamada();
    this.categoriaService.cancelarChamada();
    this.sentimentoService.cancelarChamada();
    this.autoridadeService.cancelarChamada();
    this.subGrupoService.cancelarChamada();
    this.programaService.cancelarChamada();
    this.pautaService.cancelarChamada();
    this.programaService.cancelarChamada();
  }
}
