<div class="tela">
  <mat-toolbar class="toolbar" color="primary">
    <button mat-icon-button (click)="Voltar()">
      <mat-icon>undo</mat-icon>
    </button>

    <!-- Adiciona um espaço flexível para empurrar os elementos para a direita -->
    <span class="flex-space"></span>

    <!-- <div class="user-profile">
      <div class="user-avatar">

        <img src="../../../assets/pessoa_icone.svg" alt="Imagem do usuário">

      </div>
      <div class="user-info">
        <span>{{usuario}}</span>
        <mat-icon [matMenuTriggerFor]="userMenu">arrow_drop_down</mat-icon>
      </div>
    </div> -->
  </mat-toolbar>

  <div class="forms">
    <img class="logo" src="../../../assets/logo_clipei_novo.png" alt="" />

    <form class="form-login" [formGroup]="emailForm">
      <span class="titulo-forms">Solicitar E-mail de redefinição de senha</span>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>Login</mat-label>
        <input
          formControlName="login"
          type="text"
          matInput
          placeholder="Login"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>E-mail</mat-label>
        <input
          formControlName="email"
          type="text"
          matInput
          placeholder="E-mail"
          required
        />
        <mat-error *ngIf="emailForm.controls.email.invalid">
          E-mail inválido
        </mat-error>
      </mat-form-field>

      <!-- routerLink="/home"   -->
      <button
        (click)="TrocarSenha()"
        mat-raised-button
        class="button-login"
        color="primary"
        [disabled]="emailForm.invalid || desativaBotao"
      >
        <mat-progress-spinner
          class="spinner"
          diameter="25"
          color="accent"
          *ngIf="envioLink"
          mode="indeterminate"
        ></mat-progress-spinner>
        <p>Solicitar e-mail de redefinição de senha</p>
      </button>
    </form>

    <div class="errors">
      <mat-error class="hidden-error" *ngIf="loginOuEmailInvalido"
        >Login ou E-mail Incorretos</mat-error
      >
    </div>
  </div>
</div>
