import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Color, EChartsOption } from 'echarts';
import { EChartTheme } from 'src/assets/echarts-theme';
import * as echarts from 'echarts/core';

@Component({
  selector: 'app-bar-chart-line-chart',
  templateUrl: './bar-chart-line-chart.component.html',
  styleUrls: ['./bar-chart-line-chart.component.scss'],
})
export class BarChartLineChartComponent implements OnInit {
  @ViewChild('chartContainer', { static: true }) chartElement!: ElementRef;
  @Input() data: any[][] = [];
  @Input() color: Color[] = [];
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() datas: any[] = [];
  @Input() cols: number = 2;
  // @Input() invert: boolean = false;
  @Input() lastTypeSelected: string = 'line';
  @Output() lastTypeSelectedChange = new EventEmitter<string>();
  @Output() chartImage = new EventEmitter<{
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }>();

  chartInstance: any;
  options: EChartsOption = {};
  mergeOptions: EChartsOption = {};
  tema: EChartsOption = EChartTheme.theme;

  constructor() {}

  private generateSeries(): any {
    return this.data.map((item: any) => ({
      type: this.lastTypeSelected === 'line' ? 'line' : 'bar',
      name: item[0],
      data: item.slice(1),
      symbol: 'circle',
      symbolSize: 8,
      showSymbol: true,
      lineStyle: { width: 3 },
    }));
  }

  private commonOptions(): EChartsOption {
    return {
      label: {
        show: true,
        position: 'top',
        formatter: (params: any) => (params.value !== 0 ? params.value : ''),
        fontSize: 10,
      },
      toolbox: {
        top: this.cols === 2 ? '' : '5%',
        feature: {
          dataZoom: {
            title: { zoom: 'Zoom', back: 'Voltar' },
            yAxisIndex: 'none',
            show: this.datas.length > 20,
          },
          magicType: {
            type: ['bar', 'line'],
            title: { bar: 'Trocar para gráfico de barra', line: 'Trocar para gráfico de linha' },
          },
          saveAsImage: { title: 'Salvar', pixelRatio: 4 },
        },
      },
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        show: this.data.length <= 5,
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: this.datas,
      },
      yAxis: { show: true },
      grid: {
        left: '3%',
        right: '3%',
        containLabel: true,
      },
      series: this.generateSeries(),
    };
  }

  ngOnInit(): void {
    this.options = {
      ...this.commonOptions(),
      title: {
        text: this.title,
        left: 'center',
        subtext: this.subTitle,
      },
      tooltip: { trigger: 'axis' },
    };
  }



  magicTypeChanged(event: any) {
    this.lastTypeSelectedChange.emit(event.currentType);

  }

  ngOnChanges(): void {
    this.alterarDados();
  }

  alterarDados() {
    this.mergeOptions = this.commonOptions();
    this.mergeOptions.color = this.color;
  }

  async imageOutput() {
    const chartContainer = document.createElement('div');
    chartContainer.style.width = '1000px';
    chartContainer.style.height = '600px';
    chartContainer.style.position = 'absolute';
    chartContainer.style.left = '-9999px';
    document.body.appendChild(chartContainer);

    echarts.registerTheme('meuTema', this.tema);
    const chart = echarts.init(chartContainer);
    const auxOptions = {
      ...this.commonOptions(),
      title: {
        text: this.title,
        left: 'center',
        subtext: this.subTitle,
      },
      color: this.color,
      animation: false,
    };

    chart.setOption(auxOptions);

    const imageDataURL = chart.getDataURL({
      type: 'jpeg',
      pixelRatio: 2,
      backgroundColor: '#fff',
      excludeComponents: ['toolbox'],
    });

    document.body.removeChild(chartContainer);

    this.chartImage.emit({
      dataURL: imageDataURL,
      imgWidth: chart.getWidth(),
      imgHeight: chart.getHeight(),
    });
  }
}
