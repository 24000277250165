<div class="tela">

  <mat-drawer-container class="drawer-container">

    <mat-drawer #drawer class="drawer" mode="side">

      <div class="container-drawer">
        <div class="div-logo-clipei">
          <img class="logo-clipei" src="../../../assets/logo_clipei.png" alt="" />
        </div>
        <hr class="divider" />

        <mat-button-toggle-group [formControl]="Relatorio" (change)="navigateTo()">

          <mat-button-toggle *ngIf='exibeImprensa' [checked]="navegacao == 1 ?true:false" value="home/imprensa">
            <mat-icon matPrefix class="std_gray">newspaper</mat-icon>
            Imprensa
          </mat-button-toggle>

          <mat-button-toggle *ngIf='exibeJuridico' [checked]="navegacao == 2 ?true:false" value="home/juridico">
            <mat-icon matPrefix class="std_gray">gavel</mat-icon>
            Juridico
          </mat-button-toggle>

          <mat-button-toggle *ngIf='exibeLegislativo' [checked]="navegacao == 3 ?true:false" value="home/legislativo">
            <mat-icon matPrefix class="std_gray">balance</mat-icon>
            Sessões Legislativas
          </mat-button-toggle>

          <mat-button-toggle *ngIf='exibeComunidade' [checked]="navegacao == 4 ?true:false" value="home/comunidade">
            <mat-icon matPrefix class="std_gray">groups_2</mat-icon>
            Comunidade
          </mat-button-toggle>

        </mat-button-toggle-group>

      </div>

      <img src="../../../assets/logo_descubra.svg" class="logo-descubra" alt="">
    </mat-drawer>

    <mat-toolbar class="toolbar" color="primary">
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <!-- Adiciona um espaço flexível para empurrar os elementos para a direita -->
      <span class="flex-space"></span>

      <div class="user-profile">
        <div class="user-avatar">
          <!-- Insira a imagem do usuário aqui -->
          <img src="../../../assets/pessoa_icone.svg" alt="Imagem do usuário"> -->
          <!-- <img *ngIf="avatar != 'null'"  src={{avatar}} alt="" />
          <img *ngIf="avatar == 'null'"  src="../../../assets/pessoa_icone.svg" alt="" /> -->
        </div>
        <div class="user-info">
          <span>{{usuario}}</span>
          <mat-icon [matMenuTriggerFor]="userMenu">arrow_drop_down</mat-icon>
        </div>
      </div>
    </mat-toolbar>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="deslogar()">Sair</button>
      <button mat-menu-item (click)="TrocarSenha()">Alterar Senha</button>
      <button mat-menu-item (click)="Administrativo()" *ngIf="admin">Visão Administrativa</button>
    </mat-menu>

    <router-outlet></router-outlet>


  </mat-drawer-container>
</div>
