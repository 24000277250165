import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import * as Cryptograf from 'crypto-js';
import { environment } from 'src/environment/environments';

export const authGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.estaLogado()) {
    return true;
  } else {
    router.navigate(['/login']);
    return false;
  }
};

export const authGuardJuridico = () => {
  const router = inject(Router);

  const bytes = Cryptograf.AES.decrypt(
    JSON.parse(localStorage.getItem('conteudos') as string),
    environment.cryptoKey
  );
  const conteudos = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

  if (conteudos && conteudos.includes(2)) {
    return true;
  } else {
    router.navigate(['/home/legislativo']);
    return false;
  }
};

export const authGuardImprensa = () => {
  const router = inject(Router);

  const bytes = Cryptograf.AES.decrypt(
    JSON.parse(localStorage.getItem('conteudos') as string),
    environment.cryptoKey
  );
  const conteudos = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

  if (conteudos && conteudos.includes(1)) {
    return true;
  } else {
    router.navigate(['/home/juridico']);
    return false;
  }
};

export const authGuardLegislativo = () => {
  const router = inject(Router);

  const bytes = Cryptograf.AES.decrypt(
    JSON.parse(localStorage.getItem('conteudos') as string),
    environment.cryptoKey
  );
  const conteudos = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

  if (conteudos && conteudos.includes(3)) {
    return true;
  } else {
    router.navigate(['/home/comunidade']);
    return false;
  }
};

export const authGuardComunidade = () => {
  const router = inject(Router);

  const bytes = Cryptograf.AES.decrypt(
    JSON.parse(localStorage.getItem('conteudos') as string),
    environment.cryptoKey
  );
  const conteudos = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

  if (conteudos && conteudos.includes(4)) {
    return true;
  } else {
    router.navigate(['/home/imprensa']);
    return false;
  }
};

export const authGuardAdmin = () => {
  const router = inject(Router);

  const bytes = Cryptograf.AES.decrypt(
    JSON.parse(localStorage.getItem('userType') as string),
    environment.cryptoKey
  );
  const isAdmin = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

  if (isAdmin == true) {
    return true;
  } else {
    router.navigate(['/home/imprensa']);
    return false;
  }
};
