import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import type { EChartsOption } from 'echarts';
import { EChartTheme } from 'src/assets/echarts-theme';
import * as echarts from 'echarts/core';

@Component({
  selector: 'app-sub-doughnut-chart',
  templateUrl: './sub-doughnut-chart.component.html',
  styleUrls: ['./sub-doughnut-chart.component.scss'],
})
export class SubDoughnutChartComponent implements OnInit, OnChanges {
  @Input() data: any[][] = [];
  // As cores são passadas como um array de strings
  @Input() color: string[] = [];
  @Input() title: string = '';

  @Output() chartImage = new EventEmitter<{
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }>();

  @ViewChild('chart1', { static: true }) chartElement!: ElementRef;

  options: EChartsOption = {};
  mergeOptions: EChartsOption = {};
  tema: EChartsOption = EChartTheme.theme;

  constructor() {}

  ngOnInit(): void {
    this.options = {
      title: {
        text: this.title,
        left: 'center',
      },

      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            title: 'Salvar',
            pixelRatio: 4,
          },
        },
      },

      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
      },

      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          if (params && params.data && Array.isArray(params.data)) {
            return `${params.data[0]}<br/> ${params.data[1]}  <strong>${params.data[2]}</strong>`; // Exibe o segundo elemento (índice 1) de cada array
          }
          return '';
        },
      },

      dataset: {
        source: this.data,
      },

      series: [
        {
          type: 'pie',
          // faz com que o gráfico seja um donut
          radius: ['40%', '70%'],
          label: {
            // mostra a porcentagem referente a cada fatia
            formatter: '{@[1]} ({@[2]})',
          },
          top: '5%',
          bottom: '5%',
        },
      ],
    };
  }

  ngOnChanges(): void {
    this.atualizarDados();
  }

  atualizarDados() {
    this.mergeOptions = {
      color: this.color,
      dataset: {
        source: this.data,
      },
    };
  }

  async imageOutput() {
    const chartContainer = document.createElement('div');
    chartContainer.style.width = '800px'; // largura desejada
    chartContainer.style.height = '500px'; // altura desejada
    chartContainer.style.position = 'absolute';
    chartContainer.style.left = '-9999px'; // movendo para fora da tela para renderizar invisivelmente

    document.body.appendChild(chartContainer);

    echarts.registerTheme('meuTema', this.tema);

    const chart = echarts.init(chartContainer, 'meuTema');

    let auxOptions = this.options;
    auxOptions.series = [
      {
        type: 'pie',
        // faz com que o gráfico seja um donut
        radius: ['40%', '70%'],
        label: {
          // mostra a porcentagem referente a cada fatia
          formatter: '{b} {@[1]} ({d}%)',
        },
        top: '5%',
        bottom: '5%',
        animation: false,
      },
    ];

    auxOptions.dataset = { source: this.data };

    auxOptions.legend = {
      orient: 'horizontal',
      bottom: 'bottom',
    };

    if (this.color.length != 0) {
      auxOptions.color = this.color;
    }

    // Renderizar o gráfico dentro do contêiner
    chart.setOption(auxOptions);

    const imageDataURL = chart.getDataURL({
      type: 'png', // ou 'svg', 'jpeg', dependendo do formato desejado
      pixelRatio: 2, // fator de resolução
      backgroundColor: '#fff', // cor de fundo opcional
      excludeComponents: ['toolbox'], // componentes a serem excluídos, se necessário
    });

    // Remover o contêiner após a exportação
    document.body.removeChild(chartContainer);

    const imgWidth = chart.getWidth(); // largura da imagem no PDF
    const imgHeight = chart.getHeight(); // altura proporcional

    const chartImageObj = {
      dataURL: imageDataURL,
      imgWidth: imgWidth,
      imgHeight: imgHeight,
    };
    this.chartImage.emit(chartImageObj);
    return;
  }
}
