import { C } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

const KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  salvarToken(token: string) {
    return localStorage.setItem(KEY, token);
  }

  excluirToken() {
    localStorage.removeItem(KEY);
    localStorage.removeItem('avatar');
    localStorage.removeItem('name');
    localStorage.removeItem('conteudos');
    localStorage.removeItem('userType');
    localStorage.removeItem('permissionSubgrupo');
    localStorage.removeItem('permissionCharts');

  }

  retornarToken() {
    // const expirationTimeInSeconds = jwtDecode<any>(token).exp;
    // const expirationDate = new Date(expirationTimeInSeconds * 1000);

    return localStorage.getItem(KEY) ?? '';
  }

  possuiToken() {
    const token = localStorage.getItem(KEY);
    if (token === null) return false;

    try {
      const expirationTimeInSeconds = jwtDecode<any>(token).exp;
      const expirationDate = new Date(expirationTimeInSeconds * 1000);

      if (expirationDate < new Date()) return false;
      else return true;
    } catch (error) {
      return false;
    }
  }
}
