import {
  Component,
  Inject,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  conteudo: string;
  keywords: any[];
  midia: string;
  site: string;
  data: any;
  veiculo: string;
  titulo: string;
  arquivo: string;
  transcricao: string;
  programa: string;
  sentimento: string;
  autoridade: string;
  partido: string;
}

@Component({
  selector: 'app-dialog-pdf',
  templateUrl: './dialog-pdf.component.html',
  styleUrls: ['./dialog-pdf.component.scss'],
})
export class DialogPdfComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  pdf: string = `${this.data.arquivo.substring(13)}`;

  closeDialog(): void {
    this.dialogRef.close();
  }
  Acessar(): void {
    // Verifica se a midia é 'site' e se o link está definido
    if (this.data.midia === 'Site' && this.data.site) {
      // Abre o site em uma nova aba do navegador
      window.open(this.data.site, '_blank');
    }
  }
}
