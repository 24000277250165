import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/core/services/home.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  showFiller = false;
  Relatorio = new FormControl('');
  usuario = localStorage.getItem('nome');
  avatar = localStorage.getItem('avatar');

  navegacao: number = 0;
  conteudos: Array<number> = [];
  exibeImprensa: boolean = false;
  exibeJuridico: boolean = false;
  exibeLegislativo: boolean = false;
  exibeComunidade: boolean = false;
  admin: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private actualroute: ActivatedRoute,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.admin = this.userService.isAdmin();
    this.conteudos = this.homeService.obterConteudos();

    if (this.conteudos.includes(1)) {
      this.exibeImprensa = true;
    }
    if (this.conteudos.includes(2)) {
      this.exibeJuridico = true;
    }
    if (this.conteudos.includes(3)) {
      this.exibeLegislativo = true;
    }
    if (this.conteudos.includes(4)) {
      this.exibeComunidade = true;
    }
    this.homeService.obterVariavel().subscribe((valor) => {
      this.navegacao = valor;
      this.cdr.detectChanges();
    });
    // this.router.navigate(['home/juridico']);
  }

  navigateTo() {
    this.router.navigate([this.Relatorio.value]);
  }

  deslogar() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  Administrativo() {
    this.router.navigate(['admin/']);
  }

  TrocarSenha() {
    this.router.navigate(['trocar-senha']);
  }
}
