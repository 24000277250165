import {
  Component,
  Inject,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  conteudo: string;
  keywords: any[];
  midia: string;
  site: string;
  data: any;
  veiculo: string;
  titulo: string;
  arquivo: string;
  transcricao: string;
  programa: string;
  sentimento: string;
  autoridade: string;
  partido: string;
}

@Component({
  selector: 'app-dialog-img',
  templateUrl: './dialog-img.component.html',
  styleUrls: ['./dialog-img.component.scss'],
})
export class DialogImgComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogImgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  img: string = `${this.data.arquivo.substring(7)}`;

  closeDialog(): void {
    this.dialogRef.close();
  }
  Acessar(): void {
    // Verifica se a midia é 'site' e se o link está definido
    if (this.data.midia === 'Site' && this.data.site) {
      // Abre o site em uma nova aba do navegador
      window.open(this.data.site, '_blank');
    }
  }

  formatarTexto(): string {
    let textoFormatado = this.data.transcricao;
    this.data.keywords.forEach((palavra) => {
      textoFormatado = textoFormatado.replace(
        new RegExp(palavra, 'gi'),
        `<strong style ="color: #ff9800;
  font-size: 18px;
  font-weight: bold;">${palavra}</strong>`
      );
    });
    return textoFormatado;
  }
}
