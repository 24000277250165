import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, catchError, takeUntil, throwError } from 'rxjs';
import { environment } from 'src/environment/environments';
import * as Cryptograf from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class DashboardLegislativoService {
  private apiUrl: string = environment.apiUrl;
  private usertype: boolean = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  sentimentoPorCategoria(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/categoria/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorVeiculo(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/veiculo/3?${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  nuvemDePalavras(filtrado: boolean, filterRequest?: string): Observable<any> {
    var bytes = Cryptograf.AES.decrypt(
      JSON.parse(localStorage.getItem('userType') as string),
      environment.cryptoKey
    );
    this.usertype = JSON.parse(bytes.toString(Cryptograf.enc.Utf8));

    if (this.usertype && !filtrado) {
      const error: any = new Error('Bad Request');
      error.status = 400;
      return throwError(() => error);
    } else {
      return this.http
        .get<any>(`${this.apiUrl}/clipping/count/words/3?${filterRequest}`, {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((error) => {
            throw error;
          })
        );
    }
  }
  listarTabela(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/clipping/paginate/${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorSentimento(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/3?${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoPorTempo(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/temporal/3?${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorSubCategoria(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/subcategoria/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  noticiaPorSentimentoDiario(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/diario/3?${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoPorOrgao(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/orgaos/3?${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoPorPartido(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/sentimento/count/partido/3?${filterRequest}`, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  autoridadesPorSentimento(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/autoridade/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoEvangelizadores(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/evangelizadores/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  sentimentoDetratores(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/detratores/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  orgaosPorTempo(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/clipping/count/orgao/temporal/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  partidoEvangelizadores(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/partido/evangelizadores/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  partidoDetratores(filterRequest?: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/sentimento/count/partido/detratores/3?${filterRequest}`,
        {
          headers: {
            authorization: localStorage.getItem('token') ?? '',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  async gerarCSV(filterRequest?: string): Promise<any> {
    try {
      const data: Blob = await new Promise((resolve, reject) => {
        this.http
          .get(`${this.apiUrl}/clipping/download-csv/3?${filterRequest}`, {
            headers: {
              authorization: localStorage.getItem('token') ?? '',
              'Access-Control-Allow-Origin': '*',
            },
            responseType: 'blob',
          })
          .subscribe(
            (response: Blob) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });

      const blob = new Blob([data as Blob], { type: 'text/csv' });
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Relatorio_Legislativo:${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}-${new Date().getHours()}h${new Date().getMinutes()}.csv`;
      link.click();
      window.URL.revokeObjectURL(downloadURL);

      return 'ok';
    } catch (error) {
      throw error;
    }
  }

  async gerarPlanilha(filterRequest?: string): Promise<any> {
    try {
      const data: Blob = await new Promise((resolve, reject) => {
        this.http
          .get(`${this.apiUrl}/clipping/download-xls/3?${filterRequest}`, {
            headers: {
              authorization: localStorage.getItem('token') ?? '',
              'Access-Control-Allow-Origin': '*',
            },
            responseType: 'blob',
          })
          .subscribe(
            (response: Blob) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });

      const blob = new Blob([data as Blob], { type: '' });
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Relatorio_Legislativo:${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}-${new Date().getHours()}h${new Date().getMinutes()}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(downloadURL);

      return 'ok';
    } catch (error) {
      throw error;
    }
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
