import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environment/environments';
import { programa } from '../model/programa';

@Injectable({
  providedIn: 'root'
})
export class ProgramaService {
  private apiUrl: string = environment.apiUrl;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  listarProgramas(conteudo: number, filtroRequest: any): Observable<programa[]> {
    var request = `${this.apiUrl}/programa/${conteudo}?`;

    if (filtroRequest != '') {
      request = request.concat(`${filtroRequest}`);
    }

    return this.http
      .get<programa[]>(request, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
