import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environment/environments';

@Injectable({
  providedIn: 'root',
})
export class GraficosService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  listarGraficos(grupo: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/graficos/?id_grupo=${grupo}`, {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
    });
  }

  salvarGrafico(grafico: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/graficos`, grafico, {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
    });
  }

  deletarGrafico(ids: { ids: Array<number> }): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/graficos`, {
      headers: {
        authorization: localStorage.getItem('token') ?? '',
      },
      body: ids,
    });
  }


}
