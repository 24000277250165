import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Cryptograf from 'crypto-js';
import { environment } from 'src/environment/environments';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private navegacao = new BehaviorSubject<number>(0);

  constructor() {}

  enviarVariavel(valor: number) {
    this.navegacao.next(valor);
  }

  obterVariavel() {
    return this.navegacao.asObservable();
  }

  obterConteudos() {
    const conteudos = JSON.parse(localStorage.getItem('conteudos') as string);
    if (conteudos) {
      const bytes = Cryptograf.AES.decrypt(conteudos, environment.cryptoKey);

      return JSON.parse(bytes.toString(Cryptograf.enc.Utf8));
    }
    return [];
  }
}
