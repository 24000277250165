import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable, map, startWith, take } from 'rxjs';
import { empresa } from 'src/app/core/model/empresa';
import { grupo } from 'src/app/core/model/grupo';
import { GraficosService } from 'src/app/core/services/graficos.service';
import { GrupoService } from 'src/app/core/services/grupo.service';
import { HomeService } from 'src/app/core/services/home.service';
import { SharedService } from 'src/app/core/services/shared.service';
import Swal from 'sweetalert2';

interface filterSelected {
  grupo: number;
}

interface grafico {
  gra_id: number;
  gra_nome: string;
  id_conteudo: number;
  id_permissao: number;
  id_tipo_informacao: number;
  permissao: boolean;
}

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraficosComponent implements OnInit {
  salvando: boolean = false;
  @ViewChild('completeGrupo') completeGrupo: MatSelect = <any>{};
  @ViewChild('completeEmpresa') completeEmpresa: MatSelect = <any>{};

  grupos: Array<grupo> = [];
  empresas: Array<empresa> = [];

  filteredOptionsGrupos?: Observable<grupo[]>;
  filteredOptionsEmpresas?: Observable<empresa[]>;

  graficos = {
    imprensa1: [] as grafico[],
    juridico1: [] as grafico[],
    sessoes1: [] as grafico[],
    comunidades1: [] as grafico[],
    imprensa2: [] as grafico[],
    juridico2: [] as grafico[],
    sessoes2: [] as grafico[],
    comunidades2: [] as grafico[],
  };

  graficosAlteradas = [] as grafico[];

  haSelecao: boolean = false;
  selectedGrupo?: number;
  // haAlteracaoFiltroGrupo: boolean = false;
  // haAlteracaoFiltroEmpresa: boolean = false;
  carregandoFiltros: boolean = false;
  erroCarregamentoFiltros: boolean = false;
  carregandoGraficos: boolean = false;
  erroCarregamentoGraficos: boolean = false;

  filterSelected: filterSelected = {} as filterSelected;
  filterTrigger: filterSelected = {} as filterSelected;

  filterForm = new FormGroup({
    grupos: new FormControl(),
    empresas: new FormControl(),
    searchTextboxGrupo: new FormControl(''),
    searchTextboxEmpresa: new FormControl(''),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private grupoService: GrupoService,
    private graficosService: GraficosService,
    private homeService: HomeService,
    private _sharedService: SharedService
  ) {
    // o código abaixo registra as alterações feitas no componente pai
    // e chama a função modalConfirmation() para o usuário poder salvar as alterações
    _sharedService.changeEmitted$.pipe(take(2)).subscribe((change) => {
      // as condições abaixo verificam se houve alteração e se o usuário já está salvando as informações
      if (change && this.graficosAlteradas.length > 0 && !this.salvando) {
        this._sharedService.emitChange(false);
        this.modalConfirmation();
      }
    });
  }

  ngOnInit(): void {
    this.homeService.enviarVariavel(5);

    Promise.all([this.listar_grupos()]).then(() => {
      this.enableFiltrosFunc();
    });
  }

  listarGraficos() {
    this.carregandoGraficos = true;
    this.erroCarregamentoGraficos = false;

    this.graficos = {
      imprensa1: [] as grafico[],
      juridico1: [] as grafico[],
      sessoes1: [] as grafico[],
      comunidades1: [] as grafico[],
      imprensa2: [] as grafico[],
      juridico2: [] as grafico[],
      sessoes2: [] as grafico[],
      comunidades2: [] as grafico[],
    };

    this.carregandoGraficos = true;
    this.graficosService.listarGraficos(this.filterSelected.grupo).subscribe({
      next: (value: Array<grafico>) => {
        value.forEach((grafico: grafico) => {
          const tipoMap: {
            [key: number]: {
              [key: number]: Array<grafico>;
            };
          } = {
            1: {
              1: this.graficos.imprensa1,
              2: this.graficos.juridico1,
              3: this.graficos.sessoes1,
              4: this.graficos.comunidades1,
            },
            2: {
              1: this.graficos.imprensa2,
              2: this.graficos.juridico2,
              3: this.graficos.sessoes2,
              4: this.graficos.comunidades2,
            },
          };

          const targetArray =
            tipoMap[grafico.id_tipo_informacao]?.[grafico.id_conteudo];

          if (targetArray) {
            targetArray.push(grafico);
          }
        });

        this.haSelecao = true;
        this.carregandoGraficos = false;
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.carregandoGraficos = false;
        this.erroCarregamentoGraficos = true;
        this.cdr.detectChanges();

        if (error.status === 401) {
          Swal.fire({
            title: 'Sessão expirada',
            icon: 'error',
            text: 'Por favor, faça login novamente',
            confirmButtonColor: 'organge',
          });
        } else {
          Swal.fire({
            title: 'Houve um erro ao carregar as graficos',
            icon: 'error',
            text: 'Por favor, tente novamente',
            confirmButtonColor: 'organge',
          });
        }
      },
    });
  }

  alteraGraficos(event: boolean, grafico: grafico) {
    if (event) {
      grafico.permissao = true;
    } else {
      grafico.permissao = false;
    }

    if (this.graficosAlteradas.find((element) => element == grafico)) {
      this.graficosAlteradas.splice(this.graficosAlteradas.indexOf(grafico), 1);
    } else {
      this.graficosAlteradas.push(grafico);
    }
  }

  salvarGraficos() {
    this.salvando = true;

    const graficosAdicionar = this.graficosAlteradas.filter(
      (grafico) => grafico.permissao == true
    );

    const graficosRemover = this.graficosAlteradas.filter(
      (grafico) => grafico.permissao == false
    );

    const graficosIdsRemover: Array<number> = [];
    graficosRemover.forEach((grafico) => {
      graficosIdsRemover.push(grafico.id_permissao);
    });

    const graficosFormatAdicionar: {
      id_grafico: Array<number>;
      id_conteudo: Array<number>;
      id_grupo: Array<number>;
      id_tipo_informacao: Array<number>;
      ids: Array<number>;
    } = {
      id_grafico: [],
      id_conteudo: [],
      id_grupo: [],
      id_tipo_informacao: [],
      ids: graficosIdsRemover,
    };
    graficosAdicionar.forEach((grafico) => {
      graficosFormatAdicionar.id_grafico.push(grafico.gra_id);
      graficosFormatAdicionar.id_conteudo.push(grafico.id_conteudo);
      graficosFormatAdicionar.id_grupo.push(this.filterSelected.grupo);
      graficosFormatAdicionar.id_tipo_informacao.push(
        grafico.id_tipo_informacao
      );
    });

    this.graficosService.salvarGrafico(graficosFormatAdicionar).subscribe({
      next: (response) => {
        if (response.ids && response.ids.length > 0) {
          let obj: grafico | undefined;

          const lookup: {
            [key: number]: {
              [key: number]: Array<grafico>;
            };
          } = {
            1: {
              1: this.graficos.imprensa1,
              2: this.graficos.juridico1,
              3: this.graficos.sessoes1,
              4: this.graficos.comunidades1,
            },
            2: {
              1: this.graficos.imprensa2,
              2: this.graficos.juridico2,
              3: this.graficos.sessoes2,
              4: this.graficos.comunidades2,
            },
          };

          for (let i = 0; i < response.ids.length; i++) {
            const tipo = response.id_tipo_informacao[i];
            const conteudo = response.id_conteudo[i];
            const graficos = lookup[tipo]?.[conteudo];

            if (graficos) {
              obj = graficos.find(
                (item) => item.gra_id === response.id_grafico[i]
              );
              if (obj) obj.id_permissao = response.ids[i];
            }
          }
          this.cdr.detectChanges();
        }

        Swal.fire({
          title: 'Alterações salvas com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        });
        // Emitir a mudança para o componente pai dizendo que já pode trocar de rota
        this._sharedService.emitChange(true);
        this.graficosAlteradas = [];
        this.salvando = false;
        this.cdr.detectChanges();
      },
      error: () => {
        Swal.fire({
          title: 'Erro ao salvar alterações!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        this.salvando = false;
        this.cdr.detectChanges();
      },
    });
  }

  async addFiltroSelect(event: any, nome: keyof filterSelected, id: any) {
    if (this.graficosAlteradas.length > 0) {
      this.modalConfirmation();
    }

    // SE NÃO FOR UMA AÇÃO DO USUÁRIO, NÃO FAZER NADA
    if (event.isUserInput == false) return;

    // SE O FILTRO FOR O DE CATEGORIA
    if (event.source.selected == true) {
      this.filterSelected[nome] = id;
    }

    this.listarGraficos();
  }

  modalConfirmation() {
    Swal.fire({
      title: `Deseja salvar as alterações feitas no grupo ${this.selectedGrupo}`,
      showDenyButton: true,
      confirmButtonText: 'Salvar',
      denyButtonText: 'Não salvar',
      confirmButtonColor: 'green',
      denyButtonColor: '#d33',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.salvando = true;
        this.salvarGraficos();
      } else if (result.isDenied) {
        this.graficosAlteradas = [];
        // Emitir a mudança para o componente pai dizendo que já pode trocar de rotas
        this._sharedService.emitChange(true);
      }
    });
  }

  criarStringRequest(grupo: boolean = false) {
    var request: string = '';

    if (grupo && this.filterSelected.grupo != undefined) {
      request = request.concat(`id_grupo=`);

      request = request.concat(`${this.filterSelected.grupo},`);

      if (request.endsWith(',')) request = request.slice(0, -1);

      request = request.concat(`&`);
    }

    // Remover o último '&', se existir
    if (request.endsWith('&')) {
      request = request.slice(0, -1);
    }

    return request;
  }

  // --------------------------------- Grupo----------------------------------------------

  filterGrupo(name: string): grupo[] {
    const filterValue = name.toLowerCase();

    // if (this.filterTrigger.grupo) {
    //   this.filterForm.controls.grupos.patchValue(
    //     this.filterTrigger.grupo.split(', ')
    //   );
    // }

    let filteredList = this.grupos.filter((option) =>
      option.descricao.toLowerCase().includes(filterValue)
    );
    return filteredList;
  }

  async listar_grupos() {
    this.disableFiltrosFunc();
    this.carregandoFiltros = true;
    this.erroCarregamentoFiltros = false;

    return new Promise((resolve, reject) => {
      this.grupoService.listarGrupos('').subscribe({
        next: (value) => {
          this.enableFiltrosFunc();
          this.carregandoFiltros = false;
          this.erroCarregamentoFiltros = false;

          value.forEach((element) => {
            this.grupos.push(element);
          });

          this.filteredOptionsGrupos =
            this.filterForm.controls.searchTextboxGrupo.valueChanges.pipe(
              startWith<string | null>(''),
              map((name: string | null) => this.filterGrupo(name || '')) // Handle null value by providing an empty string
            );

          resolve(true);
        },
        error: (error) => {
          this.enableFiltrosFunc();
          this.erroCarregamentoFiltros = true;
          this.carregandoFiltros = false;

          Swal.fire({
            title: 'Houve um erro ao carregar os grupos',
            icon: 'error',
            text: 'Por favor, tente novamente',
            confirmButtonColor: 'organge',
          });

          reject(error); // Rejeitar a Promise com o erro
        },
      });
    });
  }

  disableFiltrosFunc() {
    this.filterForm.controls.grupos.disable();
    this.filterForm.controls.empresas.disable();
  }

  enableFiltrosFunc() {
    this.filterForm.controls.grupos.enable();
    this.filterForm.controls.empresas.enable();
  }
}
