import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Color, SeriesOption, type EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import { EChartTheme } from 'src/assets/echarts-theme';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent implements OnInit, OnChanges {
  @Input() data: any = {};
  @Input() color: Color[] = [];
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() label: string = '';
  @Input() useCurrency: boolean = false;
  @Input() useMinutes: boolean = false;

  @Output() chartImage = new EventEmitter<{
    dataURL: string;
    imgWidth: number;
    imgHeight: number;
  }>();

  @ViewChild('chart1', { static: true }) chartElement!: ElementRef;

  options: EChartsOption = {};
  mergeOptions: EChartsOption = {};
  tema: EChartsOption = EChartTheme.theme;

  constructor() {}

  ngOnInit(): void {
    this.initializeOptions();
  }

  ngOnChanges(): void {
    this.updateChartOptions();
  }

  initializeOptions(): void {
    this.options = {
      title: {
        text: this.title,
        left: 'center',
        subtext: this.subTitle,
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            title: 'Salvar',
            pixelRatio: 4,
          },
        },
      },
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        show: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: any) => this.formatTooltip(params),
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      label: {
        formatter: (params: any) => this.formatLabel(params.value),
      },
    };
  }

  formatCurrency(value: number): string {
    if (value >= 1e9) {
      return `R$ ${(value / 1e9).toFixed(1)}bi`;
    } else if (value >= 1e6) {
      return `R$ ${(value / 1e6).toFixed(1)}mi`;
    } else if (value >= 1e3) {
      return `R$ ${(value / 1e3).toFixed(1)}K`;
    }
    return `R$ ${value}`;
  }

  formatHours(value: number): string {
    let hours: number;
    let minutes: number;
    let seconds: number;

    if (value >= 3600) {
      hours = Math.floor(value / 3600);
      minutes = Math.floor((value % 3600) / 60);
      seconds = value % 60;
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (value >= 60) {
      minutes = Math.floor(value / 60);
      seconds = value % 60;
      return `${minutes}m ${seconds}s`;
    }
    return `${value}s`;
  }

  formatTooltip(params: any): string {
    const category = params[0].axisValue;
    const tooltipLines = params.map((param: any) => {
      const marker = param.marker ? param.marker : '';
      let value: string;
      if (this.useCurrency) value = this.formatCurrency(param.value);
      else if (this.useMinutes) value = this.formatHours(param.value);
      else value = param.value;
      return `${marker} ${param.seriesName}: ${value}`;
    });
    return `<strong>${category}</strong><br />${tooltipLines.join('<br />')}`;
  }

  formatLabel(value: number): string {
    if (value <= 5) return '';
    if (this.useCurrency) return this.formatCurrency(value);
    else if (this.useMinutes) return this.formatHours(value);
    else return value.toString();
  }

  createSeries(name: string, data: any[]): SeriesOption {
    return {
      type: 'bar',
      name: name,
      stack: 'total',
      label: {
        show: true,
        width: this.useMinutes ? 30 : 'none',
        overflow: this.useMinutes ? 'break' : 'none',
        formatter: (params: any) => this.formatLabel(params.value),
      },
      emphasis: {
        focus: 'series',
      },
      data: data,
    };
  }

  updateChartOptions(): void {
    this.mergeOptions = {
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        show: true,
      },
      color: this.color,
      dataset: {
        source: this.data,
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => value.toString(),
        },
      },
      yAxis: {
        type: 'category',
        data: this.data[this.label],
        axisLabel: {
          formatter: (value: string) =>
            value.length > 10 ? value.substring(0, 20) + '...' : value,
        },
      },
      series: this.data.quantidades.map((quantidade: any) => {
        if (quantidade.sentimento) {
          return this.createSeries(
            quantidade.sentimento,
            quantidade.quantidade
          );
        }
        return this.createSeries(quantidade.midia, quantidade.quantidade);
      }),
    };

    if (this.useCurrency) {
      this.mergeOptions.xAxis = {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => this.formatCurrency(value),
        },
      };
    } else if (this.useMinutes) {
      this.mergeOptions.xAxis = {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => this.formatHours(value),
        },
      };
    }
  }

  async imageOutput() {
    const chartContainer = document.createElement('div');
    chartContainer.style.width = '1000px';
    chartContainer.style.height = '600px';
    chartContainer.style.position = 'absolute';
    chartContainer.style.left = '-9999px';
    document.body.appendChild(chartContainer);

    echarts.registerTheme('meuTema', this.tema);

    const chart = echarts.init(chartContainer, 'meuTema');
    chart.setOption({
      ...this.options,
      ...this.mergeOptions,
      animation: false,
    });

    const imageDataURL = chart.getDataURL({
      type: 'jpeg',
      pixelRatio: 2,
      backgroundColor: '#fff',
      excludeComponents: ['toolbox'],
    });

    const imgWidth = chart.getWidth();
    const imgHeight = chart.getHeight();
    document.body.removeChild(chartContainer);

    this.chartImage.emit({
      dataURL: imageDataURL,
      imgWidth: imgWidth,
      imgHeight: imgHeight,
    });
  }
}
