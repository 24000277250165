<div class="tela">
  <mat-toolbar class="toolbar" color="primary">
    <button mat-icon-button (click)="Voltar()">
      <mat-icon>undo</mat-icon>
    </button>

    <!-- Adiciona um espaço flexível para empurrar os elementos para a direita -->
    <span class="flex-space"></span>
  </mat-toolbar>

  <div class="forms" *ngIf="this.validadeToken == 1">
    <img class="logo" src="../../../assets/logo_clipei_novo.png" alt="" />

    <form class="form-login" [formGroup]="passwordForm">
      <span class="titulo-forms">Redefinir Senha</span>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>Nova Senha</mat-label>
        <input
          formControlName="novaSenha"
          [type]="hideNewPassword ? 'password' : 'text'"
          matInput
          placeholder="Senha"
          required
        />
        <mat-icon
          matSuffix
          class="std_gray"
          (click)="visibilidadeSenha('novaSenha')"
        >
          {{ hideNewPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-login">
        <mat-label>Confirmar Senha</mat-label>
        <input
          formControlName="confirmSenha"
          [type]="hideConfirmNewPassword ? 'password' : 'text'"
          matInput
          placeholder="Senha"
          required
        />
        <mat-icon
          matSuffix
          class="std_gray"
          (click)="visibilidadeSenha('confirmSenha')"
        >
          {{ hideConfirmNewPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>

      <!-- routerLink="/home"   -->
      <button
        (click)="TrocarSenha()"
        mat-raised-button
        class="button-login"
        color="primary"
        [disabled]="desativaBotao"
      >
        <mat-progress-spinner
          class="spinner"
          diameter="25"
          color="accent"
          *ngIf="isLoading"
          mode="indeterminate"
        ></mat-progress-spinner>
        <p>Redefinir Senha</p>
      </button>
    </form>
  </div>

  <div class="tela center" *ngIf="this.validadeToken == 2">
    <mat-icon class="icon">sentiment_very_dissatisfied_icon</mat-icon>

    <p class="important">
      Ops! Parece que esse link de redefinição de senha não é valido.
    </p>
    <p></p>
    <p>Sua redefinição de seja já expirou ou o link já foi usado</p>
    <p>Tente redefinir sua senha novamente.</p>

    <button
      mat-raised-button
      class="button-voltar"
      color="primary"
      (click)="Voltar()"
    >
      Voltar para página de login
    </button>
  </div>
</div>
