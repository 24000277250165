import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environment/environments';
import { subGrupo } from '../model/subgrupo';

@Injectable({
  providedIn: 'root',
})
export class SubGrupoService {
  private apiUrl: string = environment.apiUrl;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  listarSubGrupos(
    conteudo: number,
    filters?: string,
    informacao?: undefined | []
  ): Observable<subGrupo[]> {
    var request = `${this.apiUrl}/sub-grupo/${conteudo}?`;

    if (filters != '') {
      request = request.concat(`${filters}&`);
    }

    return this.http
      .get<subGrupo[]>(request, {
        headers: {
          authorization: localStorage.getItem('token') ?? '',
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  cancelarChamada() {
    this.ngUnsubscribe.next(); // Emitir um valor para cancelar a subscrição
  }
}
