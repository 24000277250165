<mat-expansion-panel
  class="filtro"
  [class.carregando]="carregandoFiltros"
  [expanded]="true"
>
  <mat-progress-spinner
    class="spinner"
    *ngIf="carregandoFiltros"
    mode="indeterminate"
  ></mat-progress-spinner>

  <mat-expansion-panel-header *ngIf="!erroCarregamentoFiltros">
    <h2
      class="align-center"
      *ngIf="filterSelected.grupo && !erroCarregamentoFiltros"
    >
      Colunas Visiveis Para {{ selectedGrupo }}
    </h2>
    <h2
      class="align-center"
      *ngIf="!filterSelected.grupo && !erroCarregamentoFiltros"
    >
      Selecione um Grupo
    </h2>
  </mat-expansion-panel-header>

  <button
    *ngIf="erroCarregamentoFiltros"
    mat-raised-button
    (click)="listar_grupos()"
    class="button-reload"
    color="primary"
  >
    Recarregar Filtros
  </button>
  <!-- Cabeçalho do expansion panel -------------------------------------------------------------------------- -->

  <!-- <h1>Gerenciamento de Colunas por Grupo</h1> -->

  <mat-grid-list *ngIf="!erroCarregamentoFiltros" [cols]="2" rowHeight="80px">
    <!-- Filtro de empresa------------------------------------------------------------------------
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Empresa</mat-label>

            <mat-select #completeEmpresa placeholder="Empresa" multiple="true" (closed)="clearSearch('empresas')"
              [formControl]="filterForm.controls.empresas">

              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input type="text" class="search-input" [formControl]="filterForm.controls.searchTextboxEmpresa" matInput>
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{filterTrigger.empresa}}</mat-select-trigger>

              <mat-option *ngFor="let option of filteredOptionsEmpresas | async"
                (onSelectionChange)="addFiltroSelect($event,'empresa', option.id)" [value]="option.nomefantasia">
                {{option.nomefantasia}}
              </mat-option>

            </mat-select>

          </mat-form-field>
        </mat-grid-tile> -->

    <!-- Filtro de grupo------------------------------------------------------------------------ -->
    <mat-grid-tile *ngIf="!erroCarregamentoFiltros">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Grupo</mat-label>
        <mat-select
          #completeGrupo
          placeholder="Grupo"
          multiple="false"
          [(value)]="selectedGrupo"
          [formControl]="filterForm.controls.grupos"
        >
          <div class="icon-input">
            <mat-icon class="search-icon">search</mat-icon>
            <input
              type="text"
              class="search-input"
              [formControl]="filterForm.controls.searchTextboxGrupo"
              matInput
            />
          </div>

          <mat-divider></mat-divider>
          <!-- <mat-select-trigger>{{filterTrigger.grupo}}</mat-select-trigger> -->

          <mat-option
            *ngFor="let option of filteredOptionsGrupos | async"
            (onSelectionChange)="addFiltroSelect($event, 'grupo', option.id)"
            [value]="option.descricao"
          >
            {{ option.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- <div class="filtrar-limpar">
        <button mat-raised-button  (click)="listarColunas()" class="button-login" color="primary">
          Filtrar
        </button>
        <button mat-raised-button (click)="limparFiltros()" class="button-login"
          color="primary">
          Limpar Filtros
        </button>
      </div> -->
</mat-expansion-panel>

<mat-card *ngIf="!haSelecao && !carregandoColunas && !erroCarregamentoColunas">
  <h2>Selecione um grupo para editar as Colunas</h2>
</mat-card>

<mat-card *ngIf="carregandoColunas && !erroCarregamentoColunas">
  <!-- <h2>Carregando...</h2> -->
  <mat-progress-spinner
    class="loadingColumns"
    *ngIf="carregandoColunas"
    mode="indeterminate"
  ></mat-progress-spinner>
</mat-card>

<mat-card *ngIf="erroCarregamentoColunas && !carregandoColunas">
  <button
    mat-raised-button
    (click)="listarColunas()"
    class="button-reload"
    color="primary"
  >
    Recarregar Colunas
  </button>
</mat-card>

<mat-card *ngIf="haSelecao && !carregandoColunas && !erroCarregamentoColunas">
  <mat-card-content>
    <mat-tab-group dynamicHeight>
      <!-- -------------------------------------------------------------------------------------------------------- -->
      <mat-tab label="Noticias / Publicações">
        <!-- Aba de permissões das colunas do relatório de Imprensa para o tipo de informação Notícias/Publicações -->
        <div *ngIf="colunas.imprensa1.length > 0">
          <h3>Relatório de Imprensa</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.imprensa1">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>

          <hr />
        </div>

        <!-- Aba de permissões das colunas do relatório de Jurídico para o tipo de informação Notícias/Publicações -->
        <div *ngIf="colunas.juridico1.length > 0">
          <h3>Relatório Jurídico</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.juridico1">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>

          <hr />
        </div>

        <!-- Aba de permissões das colunas do relatório de Sessões Legislativas para o tipo de informação Notícias/Publicações -->
        <div *ngIf="colunas.sessoes1.length > 0">
          <h3>Relatório de Sessões Legislativas</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.sessoes1">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>

          <hr />
        </div>

        <!-- Aba de permissões das colunas do relatório de Comunidades para o tipo de informação Notícias/Publicações -->
        <div *ngIf="colunas.comunidades1.length > 0">
          <h3>Relatório de Comunidades</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.comunidades1">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>
        </div>
      </mat-tab>

      <!-- -------------------------------------------------------------------------------------------------------- -->
      <mat-tab label="Notícias de Interesse">
        <!-- Aba de permissões das colunas do relatório de Imprensa para o tipo de informação Notícias de Interesse -->
        <div *ngIf="colunas.imprensa2.length > 0">
          <h3>Relatório de Imprensa</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.imprensa2">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>

          <hr />
        </div>

        <!-- Aba de permissões das colunas do relatório de Jurídico para o tipo de informação Notícias de Interesse -->
        <div *ngIf="colunas.juridico2.length > 0">
          <h3>Relatório Jurídico</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.juridico2">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>

          <hr />
        </div>

        <!-- Aba de permissões das colunas do relatório de Sessões Legislativas para o tipo de informação Notícias de Interesse -->
        <div *ngIf="colunas.sessoes2.length > 0">
          <h3>Relatório de Sessões Legislativas</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.sessoes2">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>

          <hr />
        </div>

        <!-- Aba de permissões das colunas do relatório de Comunidades para o tipo de informação Notícias de Interesse -->
        <div *ngIf="colunas.comunidades2.length > 0">
          <h3>Relatório de Comunidades</h3>
          <h4>Colunas:</h4>

          <span class="example-list-section">
            <mat-grid-list [cols]="2" rowHeight="35px">
              <ul>
                <li *ngFor="let coluna of colunas.comunidades2">
                  <mat-grid-tile>
                    <mat-checkbox
                      [checked]="coluna.permissao"
                      color="primary"
                      (change)="alteraColunas($event.checked, coluna)"
                    >
                      {{ coluna.col_nome }}
                    </mat-checkbox>
                  </mat-grid-tile>
                </li>
              </ul>
            </mat-grid-list>
          </span>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

<div class="button-fixed" *ngIf="!this.salvando">
  <button
    mat-raised-button
    (click)="salvarColunas()"
    class="button-login"
    color="primary"
    [disabled]="!(this.colunasAlteradas.length > 0)"
  >
    Salvar
  </button>
</div>

<div class="button-fixed" *ngIf="this.salvando">
  <button mat-raised-button class="button-login" color="primary">
    <mat-progress-spinner
      mode="indeterminate"
      color="accent"
      diameter="25"
    ></mat-progress-spinner>
  </button>
</div>
