<mat-expansion-panel
  class="filtro"
  [(expanded)]="panelExpansion"
  [disabled]="erroCarregamentoFiltros"
  [class.carregando]="carregandoFiltros && !erroCarregamentoFiltros"
>
  <!-- Cabeçalho do expansion panel -------------------------------------------------------------------------- -->
  <mat-expansion-panel-header>
    <mat-panel-title> Filtros </mat-panel-title>
    <mat-panel-description>
      <div class="chipspace">
        <mat-chip-listbox
          *ngIf="!carregandoFiltrosInit && !erroCarregamentoFiltros"
        >
          <mat-chip-row *ngFor="let filterChip of chipShow">
            {{ filterChip }}
          </mat-chip-row>
        </mat-chip-listbox>

        <mat-chip-listbox
          *ngIf="carregandoFiltrosInit && !erroCarregamentoFiltros"
        >
          <mat-chip-row> Carregando Filtros... </mat-chip-row>
        </mat-chip-listbox>

        <mat-chip-listbox *ngIf="erroCarregamentoFiltros">
          <button
            mat-raised-button
            (click)="recarregarFiltros()"
            class="button-login"
            color="primary"
          >
            Recarregar Filtros
          </button>
        </mat-chip-listbox>
      </div>
      <mat-icon>filter_alt</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <!-- Filtros do expansion panel -------------------------------------------------------------------------- -->
  <div>
    <form [formGroup]="filterForm">
      <mat-grid-list [cols]="isTelaMenorQue500px" rowHeight="80px">
        <!-- Filtro de tipo-Informação------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Tipo de Informação</mat-label>

            <mat-select
              #completeTipoInformacao
              placeholder="Tipo de Informação"
              multiple="false"
              (closed)="Interfiltragem('tipoInformacao')"
              [formControl]="filterForm.controls.tipoInformacao"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="
                    filterForm.controls.searchTextboxTipoInformacao
                  "
                  matInput
                />
              </div>

              <mat-divider></mat-divider>

              <mat-option
                *ngFor="let option of filteredOptionsTiposInformacao | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'tipoInformacao', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de grupo------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Grupo</mat-label>

            <mat-select
              #completeGrupo
              placeholder="Grupo"
              multiple="true"
              (closed)="Interfiltragem('grupos')"
              [formControl]="filterForm.controls.grupos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxGrupo"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{ filterTrigger.grupo }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsGrupos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'grupo', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Data inicial</mat-label>
            <input
              matInput
              readonly
              formControlName="dataInicial"
              (blur)="addFiltroInput('dataInicial')"
              (dateChange)="setDate($event)"
              [max]="maxDate"
              [matDatepicker]="data_inicial"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="data_inicial"
              [disabled]="disableFiltros"
            ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #data_inicial></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de data ------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Data final</mat-label>
            <input
              matInput
              readonly
              formControlName="dataFinal"
              (blur)="addFiltroInput('dataFinal')"
              (dateChange)="setDate($event)"
              [max]="todayDate"
              [min]="minDate"
              [matDatepicker]="data_final"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="data_final"
              [disabled]="disableFiltros"
            ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #data_final></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de empresa------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Empresa</mat-label>

            <mat-select
              #completeEmpresa
              placeholder="Empresa"
              multiple="true"
              (closed)="Interfiltragem('empresas')"
              [formControl]="filterForm.controls.empresas"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxEmpresa"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.empresa
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsEmpresas | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'empresa', option.id)
                "
                [value]="option.nomefantasia"
              >
                {{ option.nomefantasia }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de midia------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Midia</mat-label>

            <mat-select
              #completeMidia
              placeholder="Midia"
              multiple="true"
              (closed)="Interfiltragem('midias')"
              [formControl]="filterForm.controls.midias"
            >
              <mat-select-trigger>{{ filterTrigger.midia }}</mat-select-trigger>

              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxMidia"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>

              <mat-option
                *ngFor="let option of filteredOptionsMidias | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'midia', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de Veiculo------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Órgão</mat-label>

            <mat-select
              #completeVeiculo
              placeholder="Órgão"
              multiple="true"
              (closed)="Interfiltragem('veiculos')"
              [formControl]="filterForm.controls.veiculos"
            >
              <mat-optgroup>
                <div class="icon-input">
                  <mat-icon class="search-icon">search</mat-icon>
                  <input
                    type="text"
                    class="search-input"
                    [formControl]="filterForm.controls.searchTextboxVeiculo"
                    matInput
                  />
                </div>
              </mat-optgroup>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.veiculo
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsVeiculos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'veiculo', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de categoria------------------------------------------------------------------------ -->
        <mat-grid-tile [colspan]="1">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Categoria</mat-label>

            <mat-select
              #completeCategoria
              placeholder="Categoria"
              multiple="true"
              (closed)="Interfiltragem('categorias')"
              [formControl]="filterForm.controls.categorias"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxCategoria"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.categoria
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsCategorias | async"
                (onSelectionChange)="
                  addFiltroSelect(
                    $event,
                    'categoria',
                    option.id,
                    option.id_categoriapai
                  )
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de sentimento------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Classificação</mat-label>

            <mat-select
              #completeSentimento
              placeholder="Classificação"
              multiple="true"
              (closed)="Interfiltragem('sentimentos')"
              [formControl]="filterForm.controls.sentimentos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxSentimento"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.sentimento
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsSentimentos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'sentimento', option.sentimento)
                "
                [value]="option.sentimento"
              >
                {{ option.sentimento }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de autoridade------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Autoridade</mat-label>
            <mat-select
              #completeAutoridade
              placeholder="Autoridade"
              multiple="true"
              (closed)="Interfiltragem('autoridades')"
              [formControl]="filterForm.controls.autoridades"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxAutoridade"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.autoridade
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsAutoridades | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'autoridade', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de subGrupo------------------------------------------------------------------------ -->
        <mat-grid-tile *ngIf="permissaoSuperintendencia">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Superintendência</mat-label>

            <mat-select
              #completeSubGrupo
              placeholder="Superintendência"
              multiple="true"
              (closed)="Interfiltragem('subGrupos')"
              [formControl]="filterForm.controls.subGrupos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxSubGrupo"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.subGrupo
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsSubGrupos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'subGrupo', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de partido------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Partido</mat-label>

            <mat-select
              #completePartido
              placeholder="Partido"
              multiple="true"
              (closed)="Interfiltragem('partidos')"
              [formControl]="filterForm.controls.partidos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxPartido"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.partido
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsPartidos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'partido', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de Título ------------------------------------------------------------------------ -->
        <mat-grid-tile [colspan]="numbertitle">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Título ou Palavra Chave</mat-label>
            <input
              type="text"
              placeholder="Título ou Palavra Chave"
              (blur)="addFiltroInput('titulo')"
              matInput
              formControlName="titulo"
            />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>

    <div class="filtrar-limpar">
      <button
        mat-raised-button
        (click)="filtrar()"
        class="button-login"
        color="primary"
      >
        Filtrar
      </button>
      <button
        mat-raised-button
        [disabled]="validClearFilter()"
        (click)="limparFiltros()"
        class="button-login"
        color="primary"
      >
        Limpar Filtros
      </button>
    </div>
  </div>

  <mat-progress-spinner
    class="spinner"
    *ngIf="carregandoFiltros"
    mode="indeterminate"
  ></mat-progress-spinner>
</mat-expansion-panel>

<mat-grid-list [cols]="ResponsiveCards" rowHeight="100px">
  <mat-grid-tile>
    <mat-card class="totalNoticias">
      <mat-card-content *ngIf="!this.carregandoLenth">
        <b>
          <span class="destaque">
            {{ length }}
          </span>
          Notícias Encontradas</b
        >
      </mat-card-content>
      <mat-card-content *ngIf="this.carregandoLenth">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile>
    <mat-card class="totalNoticias">
      <mat-card-content *ngIf="!this.carregandoSent">
        <b style="color: #77c4a8">
          <span class="destaque">
            {{ positiva }}
          </span>
          Notícias Positivas</b
        >
      </mat-card-content>
      <mat-card-content *ngIf="this.carregandoSent">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile>
    <mat-card class="totalNoticias">
      <mat-card-content *ngIf="!this.carregandoSent">
        <b style="color: #f5c869">
          <span class="destaque">
            {{ neutra }}
          </span>
          Notícias Neutras</b
        >
      </mat-card-content>
      <mat-card-content *ngIf="this.carregandoSent">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile>
    <mat-card class="totalNoticias">
      <mat-card-content *ngIf="!this.carregandoSent">
        <b style="color: #dd6b7f">
          <span class="destaque">
            {{ negativa }}
          </span>
          Notícias Negativas</b
        >
      </mat-card-content>
      <mat-card-content *ngIf="this.carregandoSent">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<hr />

<mat-tab-group dynamicHeight>
  <mat-tab label="Dasboard Jurídico">
    <div #dashboard>
      <h1>Jurídico</h1>
      <button
        mat-raised-button
        (click)="gerarPDF()"
        class="button-login"
        color="primary"
      >
        Gerar PDF
      </button>

      <!-- ----------------------------------Gráficos----------------------------------------------------------------- -->

      <mat-grid-list [cols]="numbergraphs" rowHeight="480px">
        <!-- -------------Gráfico notícia diario------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Classificação dos Últimos 7 dias'
            )
          "
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSentimentoDiario &&
              dataNoticiaPorSentimentoDiario.datas.length > 0 &&
              !erroNoticiaPorSentimentoDiario
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                [data]="dataNoticiaPorSentimentoDiario"
                title="Notícias por Classificação dos Últimos 7 dias"
                [color]="coresSentimento"
                label="datas"
                (chartImage)="handleChartImage($event)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNoticiaPorSentimentoDiario && !erroNoticiaPorSentimentoDiario
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSentimentoDiario &&
              dataNoticiaPorSentimentoDiario.datas.length == 0 &&
              !erroNoticiaPorSentimentoDiario
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação dos Últimos 7 dias</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSentimentoDiario">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico sentimento por orgão ------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Classificação por Órgão')"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorOrgao &&
              dataSentimentoPorOrgao.orgaos.length > 0 &&
              !erroSentimentoPorOrgao
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                [data]="dataSentimentoPorOrgao"
                title="Classificação por Órgão"
                [color]="coresSentimento"
                label="orgaos"
                (chartImage)="handleChartImage($event)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataSentimentoPorOrgao && !erroSentimentoPorOrgao">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorOrgao &&
              dataSentimentoPorOrgao.orgaos.length == 0 &&
              !erroSentimentoPorOrgao
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação à
                <strong>Classificação por Órgão</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorOrgao">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico nuvem de palavras------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Nuvem de Palavras')"
          [colspan]="ResponsiveNuvem"
        >
          <mat-card
            *ngIf="
              dataNuvemDePalavras &&
              dataNuvemDePalavras.length > 0 &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content>
              <app-word-cloud
                #charts
                [data]="dataNuvemDePalavras"
                title="Palavras que mais apareceram"
                (chartImage)="handleChartImage($event)"
                [haCentimetragem]="numbergraphs == 1"
              /><!-- #charts -->
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNuvemDePalavras &&
              !needFilterWordCloud &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataNuvemDePalavras?.length == 0 && !erroNuvemDePalavras"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Palavras que mais apareceram</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNuvemDePalavras &&
              needFilterWordCloud &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content class="loading-content">
              <p>
                É necessário selecionar um filtro para exibir as
                <strong>Palavras que mais aparecem</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNuvemDePalavras">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list [cols]="numbergraphs" rowHeight="350px">
        <!-- -------------Gráfico notícia por categoria ------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Categoria (Top 15)')
          "
          rowspan="2"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorCategoria &&
              dataNoticiaPorCategoria.categorias.length > 0 &&
              !erroNoticiaPorCategoria
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="coresSentimento"
                [data]="dataNoticiaPorCategoria"
                label="categorias"
                title="Notícias por Categoria"
              ></app-stacked-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataNoticiaPorCategoria && !erroNoticiaPorCategoria"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorCategoria &&
              dataNoticiaPorCategoria.categorias.length == 0 &&
              !erroNoticiaPorCategoria
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Categoria</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorCategoria">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico notícia por subcategoria------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Subcategoria (Top 15)')
          "
          rowspan="2"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSubCategoria &&
              dataNoticiaPorSubCategoria.subcategorias.length > 0 &&
              !erroNoticiaPorSubCategoria
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorSubCategoria"
                [color]="coresSentimento"
                label="subcategorias"
                title="Notícias por Subcategoria"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataNoticiaPorSubCategoria && !erroNoticiaPorSubCategoria"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSubCategoria &&
              dataNoticiaPorSubCategoria.subcategorias.length == 0 &&
              !erroNoticiaPorSubCategoria
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Subcategoria</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSubCategoria">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list [cols]="numbergraphs" rowHeight="480px">
        <!-- -------------Gráfico sentimento por tempo ------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Classificação (Linhas e Barras)'
            )
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorTempo &&
              dataSentimentoPorTempo.length > 0 &&
              !erroSentimentoPorTempo
            "
          >
            <mat-card-content>
              <app-bar-chart-line-chart
                #charts
                [data]="
                  sentimentoSentimentoPorTempo !== null
                    ? sentimentoSentimentoPorTempo
                    : []
                "
                [datas]="dataSentimentoPorTempo"
                [color]="colorSentimentoTempo"
                [cols]="numbergraphs"
                title="Notícias por Classificação"
                (chartImage)="handleChartImage($event)"
                [(lastTypeSelected)]="typeSelectedNoticiasPorClassificacao"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataSentimentoPorTempo && !erroSentimentoPorTempo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorTempo?.length == 0 && !erroSentimentoPorTempo
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorTempo">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico orgao por tempo------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Órgão (Linhas e Barras) (Top 5)'
            )
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            *ngIf="
              dataOrgaoPorTempo &&
              dataOrgaoPorTempo.length > 0 &&
              !erroOrgaosPorTempo
            "
          >
            <mat-card-content>
              <app-bar-chart-line-chart
                #charts
                [color]="colorOrgaoTempo"
                [data]="orgaosOrgaoPorTempo !== null ? orgaosOrgaoPorTempo : []"
                [datas]="dataOrgaoPorTempo"
                [cols]="numbergraphs"
                title="Notícias por Órgão (Top 5)"
                (chartImage)="handleChartImage($event)"
                [(lastTypeSelected)]="typeSelectedNoticiasPorOrgao"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataOrgaoPorTempo && !erroOrgaosPorTempo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataOrgaoPorTempo?.length == 0 && !erroOrgaosPorTempo"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Órgão (Top 5)</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroOrgaosPorTempo">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </mat-tab>

  <!-- ----------------------------------Tabela----------------------------------------------------------------- -->
  <mat-tab label="Tabela Jurídico">
    <h1>Tabela de dados</h1>

    <mat-card *ngIf="carregandoTabela" class="card-tabela">
      <mat-card-content class="loading-content">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="tabelaVazia" class="card-tabela">
      <mat-card-content class="loading-content">
        <b>Não há dados para exibir</b>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!carregandoTabela && !tabelaVazia" class="card-tabela">
      <div style="padding: 10px">
        <button
          mat-raised-button
          (click)="gerarCSV()"
          class="button-login"
          color="primary"
        >
          Gerar CSV
        </button>
        <button
          mat-raised-button
          (click)="gerarPlanilha()"
          class="button-login"
          color="primary"
        >
          Gerar planilha
        </button>
      </div>

      <mat-card-content style="height: fit-content">
        <div class="mat-elevation-z8">
          <section class="example-container mat-elevation-z8" tabindex="0">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="example-container"
            >
              <!-- ID Column -->
              <ng-container
                [matColumnDef]="column"
                *ngFor="let column of displayedColumns"
              >
                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
              </ng-container>

              <!-- arquivo Column -->
              <ng-container matColumnDef="arquivo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Arquivo
                </th>
                <td mat-cell *matCellDef="let row">
                  <button
                    mat-button
                    *ngIf="
                      isArqNull(row.arquivo) &&
                      !isArqAndLinkNull(row.arquivo, row.link)
                    "
                    (click)="OpenLinkInNewTab(row.link)"
                  >
                    <mat-icon>link</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isArqAndLinkNull(row.arquivo, row.link)"
                  >
                    <b>Não há arquivo</b>
                  </button>

                  <button
                    mat-button
                    *ngIf="isAudioFile(row.arquivo)"
                    (click)="
                      openDialogAudio(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Órgão'],
                        row['Título'],
                        row.arquivo,
                        row.transcricao,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>audiotrack</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isPdfFile(row.arquivo)"
                    (click)="openDialogPdf(row.arquivo)"
                  >
                    <mat-icon>picture_as_pdf</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isImageFile(row.arquivo)"
                    (click)="
                      openDialogImg(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Órgão'],
                        row['Título'],
                        row.arquivo,
                        row.transcricao,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>image</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isVideoFile(row.arquivo)"
                    (click)="
                      openDialogVideo(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Órgão'],
                        row['Título'],
                        row.arquivo,
                        row.trasnscricao,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>ondemand_video</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isTextFile(row.arquivo)"
                    (click)="
                      openDialogTranscricao(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Órgão'],
                        row['Título'],
                        row.arquivo,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>article</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns.concat('arquivo')"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumns.concat('arquivo')
                "
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow></tr>
            </table>

            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              (page)="eventosTabela($event)"
              [length]="length"
              [pageSize]="rowsPerPage"
              [pageIndex]="paginaAtual - 1"
              [showFirstLastButtons]="true"
            >
            </mat-paginator>
          </section>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
