<mat-expansion-panel
  class="filtro"
  [(expanded)]="panelExpansion"
  [disabled]="erroCarregamentoFiltros"
  [class.carregando]="carregandoFiltros && !erroCarregamentoFiltros"
>
  <!-- Cabeçalho do expansion panel -------------------------------------------------------------------------- -->
  <mat-expansion-panel-header>
    <mat-panel-title> Filtros </mat-panel-title>
    <mat-panel-description>
      <div class="chipspace">
        <mat-chip-listbox
          *ngIf="!carregandoFiltrosInit && !erroCarregamentoFiltros"
        >
          <mat-chip-row *ngFor="let filterChip of chipShow">
            {{ filterChip }}
          </mat-chip-row>
        </mat-chip-listbox>

        <mat-chip-listbox
          *ngIf="carregandoFiltrosInit && !erroCarregamentoFiltros"
        >
          <mat-chip-row> Carregando Filtros... </mat-chip-row>
        </mat-chip-listbox>

        <mat-chip-listbox *ngIf="erroCarregamentoFiltros">
          <button
            mat-raised-button
            (click)="recarregarFiltros()"
            class="button-login"
            color="primary"
          >
            Recarregar Filtros
          </button>
        </mat-chip-listbox>
      </div>
      <mat-icon>filter_alt</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <!-- Filtros do expansion panel -------------------------------------------------------------------------- -->
  <div>
    <form [formGroup]="filterForm">
      <mat-grid-list [cols]="isTelaMenorQue500px" rowHeight="80px">
        <!-- Filtro de tipo-Informação------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Tipo de Informação</mat-label>

            <mat-select
              #completeTipoInformacao
              placeholder="Tipo de Informação"
              multiple="false"
              (closed)="Interfiltragem('tipoInformacao')"
              [formControl]="filterForm.controls.tipoInformacao"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="
                    filterForm.controls.searchTextboxTipoInformacao
                  "
                  matInput
                />
              </div>

              <mat-divider></mat-divider>

              <mat-option
                *ngFor="let option of filteredOptionsTiposInformacao | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'tipoInformacao', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de grupo------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Grupo</mat-label>

            <mat-select
              #completeGrupo
              placeholder="Grupo"
              multiple="true"
              (closed)="Interfiltragem('grupos')"
              [formControl]="filterForm.controls.grupos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxGrupo"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{ filterTrigger.grupo }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsGrupos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'grupo', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de data ------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Data inicial</mat-label>
            <input
              matInput
              readonly
              formControlName="dataInicial"
              (blur)="addFiltroInput('dataInicial')"
              (dateChange)="setDate($event)"
              [max]="maxDate"
              [matDatepicker]="data_inicial"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="data_inicial"
              [disabled]="disableFiltros"
            ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #data_inicial></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de data ------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Data final</mat-label>
            <input
              matInput
              readonly
              formControlName="dataFinal"
              (blur)="addFiltroInput('dataFinal')"
              (dateChange)="setDate($event)"
              [max]="todayDate"
              [min]="minDate"
              [matDatepicker]="data_final"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="data_final"
              [disabled]="disableFiltros"
            ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #data_final></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de empresa------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Empresa</mat-label>

            <mat-select
              #completeEmpresa
              placeholder="Empresa"
              multiple="true"
              (closed)="Interfiltragem('empresas')"
              [formControl]="filterForm.controls.empresas"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxEmpresa"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.empresa
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsEmpresas | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'empresa', option.id)
                "
                [value]="option.nomefantasia"
              >
                {{ option.nomefantasia }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de midia------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Midia</mat-label>

            <mat-select
              #completeMidia
              placeholder="Midia"
              multiple="true"
              (closed)="Interfiltragem('midias')"
              [formControl]="filterForm.controls.midias"
            >
              <mat-select-trigger>{{ filterTrigger.midia }}</mat-select-trigger>

              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxMidia"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>

              <mat-option
                *ngFor="let option of filteredOptionsMidias | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'midia', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de Veiculo------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Veiculo</mat-label>

            <mat-select
              #completeVeiculo
              placeholder="Veiculo"
              multiple="true"
              (closed)="Interfiltragem('veiculos')"
              [formControl]="filterForm.controls.veiculos"
            >
              <mat-optgroup>
                <div class="icon-input">
                  <mat-icon class="search-icon">search</mat-icon>
                  <input
                    type="text"
                    class="search-input"
                    [formControl]="filterForm.controls.searchTextboxVeiculo"
                    matInput
                  />
                </div>
              </mat-optgroup>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.veiculo
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsVeiculos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'veiculo', option.id)
                "
                [value]="option.nome"
              >
                {{ option.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de categoria------------------------------------------------------------------------ -->
        <mat-grid-tile [colspan]="1">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Categoria</mat-label>

            <mat-select
              #completeCategoria
              placeholder="Categoria"
              multiple="true"
              (closed)="Interfiltragem('categorias')"
              [formControl]="filterForm.controls.categorias"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxCategoria"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.categoria
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsCategorias | async"
                (onSelectionChange)="
                  addFiltroSelect(
                    $event,
                    'categoria',
                    option.id,
                    option.id_categoriapai
                  )
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de sentimento------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Classificação</mat-label>

            <mat-select
              #completeSentimento
              placeholder="Classificação"
              multiple="true"
              (closed)="Interfiltragem('sentimentos')"
              [formControl]="filterForm.controls.sentimentos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxSentimento"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.sentimento
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsSentimentos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'sentimento', option.sentimento)
                "
                [value]="option.sentimento"
              >
                {{ option.sentimento }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de pauta------------------------------------------------------------------------ -->
        <mat-grid-tile>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Pauta</mat-label>
            <mat-select
              #completePauta
              placeholder="Pauta"
              multiple="true"
              (closed)="Interfiltragem('pautas')"
              [formControl]="filterForm.controls.pautas"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  matInput
                  [formControl]="filterForm.controls.searchTextboxPauta"
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{ filterTrigger.pauta }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsPautas | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'pauta', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de subGrupo------------------------------------------------------------------------ -->
        <mat-grid-tile *ngIf="permissaoSuperintendencia">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Superintendência</mat-label>

            <mat-select
              #completeSubGrupo
              placeholder="Superintendência"
              multiple="true"
              (closed)="Interfiltragem('subGrupos')"
              [formControl]="filterForm.controls.subGrupos"
            >
              <div class="icon-input">
                <mat-icon class="search-icon">search</mat-icon>
                <input
                  type="text"
                  class="search-input"
                  [formControl]="filterForm.controls.searchTextboxSubGrupo"
                  matInput
                />
              </div>

              <mat-divider></mat-divider>
              <mat-select-trigger>{{
                filterTrigger.subGrupo
              }}</mat-select-trigger>

              <mat-option
                *ngFor="let option of filteredOptionsSubGrupos | async"
                (onSelectionChange)="
                  addFiltroSelect($event, 'subGrupo', option.id)
                "
                [value]="option.descricao"
              >
                {{ option.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Filtro de Título ------------------------------------------------------------------------ -->
        <mat-grid-tile [colspan]="numbertitle">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Título ou Palavra Chave</mat-label>
            <input
              type="text"
              placeholder="Título ou Palavra Chave"
              (blur)="addFiltroInput('titulo')"
              matInput
              formControlName="titulo"
            />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>

    <div class="filtrar-limpar">
      <button
        mat-raised-button
        (click)="filtrar()"
        class="button-login"
        color="primary"
      >
        Filtrar
      </button>
      <button
        mat-raised-button
        [disabled]="validClearFilter()"
        (click)="limparFiltros()"
        class="button-login"
        color="primary"
      >
        Limpar Filtros
      </button>
    </div>
  </div>
  <mat-progress-spinner
    class="spinner"
    *ngIf="carregandoFiltros"
    mode="indeterminate"
  ></mat-progress-spinner>
</mat-expansion-panel>

<div class="totalizadores">
  <div class="cards">
    <mat-grid-list [cols]="ResponsiveCards" rowHeight="100px">
      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content *ngIf="!this.carregandoLenth">
            <b>
              <span class="destaque">
                {{ length }}
              </span>
              Notícias Encontradas</b
            >
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoLenth">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content *ngIf="!this.carregandoSent">
            <b style="color: #77c4a8">
              <span class="destaque">
                {{ positiva }}
              </span>
              Notícias Positivas</b
            >
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoSent">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content *ngIf="!this.carregandoSent">
            <b style="color: #f5c869">
              <span class="destaque">
                {{ neutra }}
              </span>
              Notícias Neutras</b
            >
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoSent">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-card class="totalNoticias">
          <mat-card-content *ngIf="!this.carregandoSent">
            <b style="color: #dd6b7f">
              <span class="destaque">
                {{ negativa }}
              </span>
              Notícias Negativas</b
            >
          </mat-card-content>
          <mat-card-content *ngIf="this.carregandoSent">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="cards">
    <!-- -------------Gráfico porcentagem de noticias positivas ------------------>
    <mat-grid-list [cols]="1" rowHeight="200px">
      <mat-grid-tile>
        <mat-card
          class="gauge"
          *ngIf="
            dataNoticiaPorSentimento &&
            dataNoticiaPorSentimento.length > 0 &&
            !erroNoticiaPorSentimento &&
            this.haNoticiasGauche
          "
        >
          <mat-card-content>
            <app-gauge-chart
              #charts
              (chartImage)="handleChartImage($event)"
              [data]="dataNoticiaGauge"
              [color]="colorSentimento"
              title="Saúde da Marca no Período"
            ></app-gauge-chart>

            <div class="tooltip-container">
              <mat-icon
                matTooltipPosition="right"
                matTooltipClass="red-tooltip"
                matTooltip="Termômetro de Saúde da Marca&#13;

A métrica é gerada a partir da análise das matérias jornalísticas que citam diretamente a empresa e são classificadas nos sentimentos Positivo e Negativo, tendo como ponto de partida a impressão do receptor ao ter acesso as notícias.&#13;

Abolimos o sentimento Neutro desta análise, porque a simplificação do diagnóstico evita subjetividades e torna o resultado mais seguro."
              >
                info_outlined</mat-icon
              >
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card
          class="gauge"
          *ngIf="!dataNoticiaPorSentimento && !erroNoticiaPorSentimento"
        >
          <mat-card-content class="loading-content">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
          </mat-card-content>
        </mat-card>

        <mat-card
          class="gauge"
          *ngIf="
            dataNoticiaPorSentimento?.length == 0 &&
            !erroNoticiaPorSentimento &&
            this.haNoticiasGauche
          "
        >
          <mat-card-content class="loading-content">
            <p>
              Não há dados para exibir em relação às
              <strong>Notícias por Classificação</strong>
            </p>
          </mat-card-content>
        </mat-card>

        <mat-card class="gauge" *ngIf="erroNoticiaPorSentimento">
          <mat-card-content class="loading-content">
            <p>Erro ao carregar gráfico</p>
          </mat-card-content>
        </mat-card>

        <mat-card class="gauge" *ngIf="!this.haNoticiasGauche">
          <mat-card-content class="loading-content">
            <p>Não há notícias <b>positivas</b> e nem <b>negativas</b></p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<hr />
<mat-tab-group dynamicHeight>
  <mat-tab label="Dasboard Imprensa">
    <div #dashboard>
      <div>
        <h1>Imprensa</h1>
      </div>

      <button
        mat-raised-button
        (click)="gerarPDF()"
        class="button-login"
        color="primary"
      >
        Gerar PDF
      </button>

      <!-- ----------------------------------Gráficos----------------------------------------------------------------- -->

      <mat-grid-list [cols]="numbergraphs" rowHeight="480px">
        <!-- -------------Gráfico notícia diario------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Classificação dos Últimos 7 dias'
            ) && this.difDays < 28
          "
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSentimentoDiario &&
              dataNoticiaPorSentimentoDiario.datas.length > 0 &&
              !erroNoticiaPorSentimentoDiario
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                [data]="dataNoticiaPorSentimentoDiario"
                title="Notícias por Classificação dos Últimos 7 dias"
                [color]="coresSentimento"
                label="datas"
                (chartImage)="handleChartImage($event)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNoticiaPorSentimentoDiario && !erroNoticiaPorSentimentoDiario
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSentimentoDiario &&
              dataNoticiaPorSentimentoDiario.datas.length == 0 &&
              !erroNoticiaPorSentimentoDiario
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação dos Últimos 7 dias</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSentimentoDiario">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico notícia por sentimento------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Classificação (Rosca)')
          "
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSentimento &&
              dataNoticiaPorSentimento.length > 0 &&
              !erroNoticiaPorSentimento
            "
          >
            <mat-card-content>
              <app-doughnut-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorSentimento"
                [color]="colorSentimento"
                title="Notícias por Classificação"
              ></app-doughnut-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataNoticiaPorSentimento && !erroNoticiaPorSentimento"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSentimento?.length == 0 && !erroNoticiaPorSentimento
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSentimento">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico notícia por pauta------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Notícias por Pauta')"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorPauta &&
              dataNoticiaPorPauta.length > 0 &&
              !erroNoticiaPorPauta
            "
          >
            <mat-card-content>
              <app-doughnut-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorPauta"
                [color]="colorPauta"
                title="Notícias por Pauta"
              ></app-doughnut-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataNoticiaPorPauta && !erroNoticiaPorPauta">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataNoticiaPorPauta?.length == 0 && !erroNoticiaPorPauta"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Pauta</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorPauta">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico notícia por midia------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Notícias por Tipos de Mídia')"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorMidia &&
              dataNoticiaPorMidia.length > 0 &&
              !erroNoticiaPorMidia
            "
          >
            <mat-card-content>
              <app-doughnut-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorMidia"
                [color]="colorMidia"
                title="Notícias por Tipos de Mídia"
              ></app-doughnut-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataNoticiaPorMidia && !erroNoticiaPorMidia">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataNoticiaPorMidia?.length == 0 && !erroNoticiaPorMidia"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Tipos de Mídia</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorMidia">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico centimetragem Positiva por midia------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Centimetragem Positiva por Tipos de Mídia'
            )
          "
        >
          <mat-card
            *ngIf="
              dataCentrimetragemPorMidiaPositiva &&
              dataCentrimetragemPorMidiaPositiva.length > 0 &&
              !erroCentrimetragemPorMidiaPositiva
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataCentrimetragemPorMidiaPositiva"
                [color]="colorCentrimetragemPositiva"
                [colorfull]="true"
                [useCurrency]="true"
                title="Centimetragem Positiva por Tipos de Mídia"
                [maxValueX]="maxValueCentimetragem"
                subTitle=""
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataCentrimetragemPorMidiaPositiva &&
              !erroCentrimetragemPorMidiaPositiva
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataCentrimetragemPorMidiaPositiva?.length == 0 &&
              !erroCentrimetragemPorMidiaPositiva
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação à
                <strong>Centimetragem Positiva por Tipos de Mídia</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroCentrimetragemPorMidiaPositiva">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico centimetragem Neutra por midia------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Centimetragem Neutra por Tipos de Mídia'
            )
          "
        >
          <mat-card
            *ngIf="
              dataCentrimetragemPorMidiaNeutra &&
              dataCentrimetragemPorMidiaNeutra.length > 0 &&
              !erroCentrimetragemPorMidiaNeutra
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataCentrimetragemPorMidiaNeutra"
                [color]="colorCentrimetragemNeutra"
                [colorfull]="true"
                [useCurrency]="true"
                title="Centimetragem Neutra por Tipos de Mídia"
                [maxValueX]="maxValueCentimetragem"
                subTitle=""
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataCentrimetragemPorMidiaNeutra &&
              !erroCentrimetragemPorMidiaNeutra
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataCentrimetragemPorMidiaNeutra?.length == 0 &&
              !erroCentrimetragemPorMidiaNeutra
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação à
                <strong>Centimetragem Neutra por Tipos de Mídia</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroCentrimetragemPorMidiaNeutra">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico centimetragem Positiva por veículo------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Centimetragem Positiva por Veículo')
          "
        >
          <mat-card
            *ngIf="
              dataCentrimetragemPorVeiculoPositiva &&
              dataCentrimetragemPorVeiculoPositiva.length > 0 &&
              !erroCentrimetragemPorVeiculoPositiva
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataCentrimetragemPorVeiculoPositiva"
                [color]="colorCentrimetragemVeiculosPositiva"
                [colorfull]="false"
                [useCurrency]="true"
                title="Centimetragem Positiva por Veículo (Top 15)"
                subTitle=""
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataCentrimetragemPorVeiculoPositiva &&
              !erroCentrimetragemPorVeiculoPositiva
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataCentrimetragemPorVeiculoPositiva?.length == 0 &&
              !erroCentrimetragemPorVeiculoPositiva
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação à
                <strong>Centimetragem Positiva por Veículo (Top 15)</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroCentrimetragemPorVeiculoPositiva">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico centimetragem Neutra por Veiculo------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Centimetragem Neutra por Veículo')
          "
        >
          <mat-card
            *ngIf="
              dataCentrimetragemPorVeiculoNeutra &&
              dataCentrimetragemPorVeiculoNeutra.length > 0 &&
              !erroCentrimetragemPorVeiculoNeutra
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataCentrimetragemPorVeiculoNeutra"
                [color]="colorCentrimetragemVeiculosNeutra"
                [colorfull]="false"
                [useCurrency]="true"
                title="Centimetragem Neutra por Veículo (Top 15)"
                subTitle=""
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataCentrimetragemPorVeiculoNeutra &&
              !erroCentrimetragemPorVeiculoNeutra
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataCentrimetragemPorVeiculoNeutra?.length == 0 &&
              !erroCentrimetragemPorVeiculoNeutra
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação à
                <strong>Centimetragem Neutra por Veículo (Top 15)</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroCentrimetragemPorVeiculoNeutra">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico notícia por Duracao ------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Duração por Classificação')"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorDuracao &&
              dataSentimentoPorDuracao.midias.length > 0 &&
              !erroNoticiaPorDuracao
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="coresSentimento"
                [data]="dataSentimentoPorDuracao"
                label="midias"
                [useMinutes]="true"
                title=" Duração por Classificação "
              ></app-stacked-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataSentimentoPorDuracao && !erroNoticiaPorDuracao">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorDuracao &&
              dataSentimentoPorDuracao.midias.length == 0 &&
              !erroNoticiaPorDuracao
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Classificação por Duração</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorDuracao">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico nuvem de palavras------------------>
        <mat-grid-tile
          *ngIf="this.permissionCharts.includes('Nuvem de Palavras')"
          [colspan]="ResponsiveCentimetragem"
        >
          <mat-card
            *ngIf="
              dataNuvemDePalavras &&
              dataNuvemDePalavras.length > 0 &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content>
              <app-word-cloud
                #charts
                [data]="dataNuvemDePalavras"
                title="Palavras que mais apareceram"
                [haCentimetragem]="ResponsiveCentimetragem == 1"
                (chartImage)="handleChartImage($event)"
              /><!-- #charts -->
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNuvemDePalavras &&
              !needFilterWordCloud &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataNuvemDePalavras?.length == 0 && !erroNuvemDePalavras"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Palavras que mais apareceram</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              !dataNuvemDePalavras &&
              needFilterWordCloud &&
              !erroNuvemDePalavras
            "
          >
            <mat-card-content class="loading-content">
              <p>
                É necessário selecionar um filtro para exibir as
                <strong>Palavras que mais aparecem</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNuvemDePalavras">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list [cols]="numbergraphs" rowHeight="350px">
        <!-- -------------Gráfico notícia por categoria ------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Categoria (Top 15)')
          "
          rowspan="2"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorCategoria &&
              dataSentimentoPorCategoria.categorias.length > 0 &&
              !erroNoticiaPorCategoria
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="coresSentimento"
                [data]="dataSentimentoPorCategoria"
                label="categorias"
                title="Notícias por Categoria (Top 15)"
              ></app-stacked-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataSentimentoPorCategoria && !erroNoticiaPorCategoria"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorCategoria &&
              dataSentimentoPorCategoria.categorias.length == 0 &&
              !erroNoticiaPorCategoria
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Categoria</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorCategoria">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico notícia por subcategoria------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Subcategoria (Top 15)')
          "
          rowspan="2"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorSubCategoria &&
              dataNoticiaPorSubCategoria.subcategorias.length > 0 &&
              !erroNoticiaPorSubCategoria
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataNoticiaPorSubCategoria"
                [color]="coresSentimento"
                label="subcategorias"
                title="Notícias por Subcategoria (Top 15)"
              />
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataNoticiaPorSubCategoria && !erroNoticiaPorSubCategoria"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorSubCategoria &&
              dataNoticiaPorSubCategoria.subcategorias.length == 0 &&
              !erroNoticiaPorSubCategoria
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Subcategoria</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorSubCategoria">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list [cols]="numbergraphs" rowHeight="480px">
        <!-- -------------Gráfico notícia por Veiculo ------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Veículo (Top 15)')
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            *ngIf="
              dataNoticiaPorVeiculo &&
              dataNoticiaPorVeiculo.veiculos.length > 0 &&
              !erroNoticiaPorVeiculo
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="coresSentimento"
                [data]="dataNoticiaPorVeiculo"
                label="veiculos"
                title="Notícias por Veículo (Top 15)"
              ></app-stacked-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataNoticiaPorVeiculo && !erroNoticiaPorVeiculo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataNoticiaPorVeiculo &&
              dataNoticiaPorVeiculo.veiculos.length == 0 &&
              !erroNoticiaPorVeiculo
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Veículo</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroNoticiaPorVeiculo">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico evangelizadores------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Evangelizadores (Top 15)'
            )
          "
        >
          <mat-card
            *ngIf="
              dataEvangelizadores &&
              dataEvangelizadores.length > 0 &&
              !erroEvangelizadores
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataEvangelizadores"
                [color]="colorEvangelizadores"
                [colorfull]="false"
                title="Notícias por Evangelizadores (Top 15)"
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataEvangelizadores && !erroEvangelizadores">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataEvangelizadores?.length == 0 && !erroEvangelizadores"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Evangelizadores</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroEvangelizadores">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico detratores------------------>
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes('Notícias por Detratores (Top 15)')
          "
        >
          <mat-card
            *ngIf="
              dataDetratores && dataDetratores.length > 0 && !erroDetratores
            "
          >
            <mat-card-content>
              <app-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [data]="dataDetratores"
                [color]="colorDetratores"
                [colorfull]="false"
                title="Notícias por Detratores (Top 15)"
              ></app-bar-chart>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataDetratores && !erroDetratores">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="dataDetratores?.length == 0 && !erroDetratores">
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Detratores</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroDetratores">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- -------------Gráfico sentimento por tempo diário ---------------- -->
        <!-- <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Classificação (Linhas e Barras)'
            ) && this.difDays < 31
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            class="chart-two-cols"
            *ngIf="
              dataSentimentoPorTempo &&
              dataSentimentoPorTempo.length > 0 &&
              !erroSentimentoPorTempo
            "
          >
            <mat-card-content>
              <app-bar-chart-line-chart
                #charts
                [data]="
                  sentimentoSentimentoPorTempo !== null
                    ? sentimentoSentimentoPorTempo
                    : []
                "
                [datas]="dataSentimentoPorTempo"
                [color]="colorSentimentoTempo"
                [cols]="numbergraphs"
                title="Notícias por Classificação"
                (chartImage)="handleChartImage($event)"
                [(lastTypeSelected)]="typeSelectedNoticiasPorClassificacao"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataSentimentoPorTempo && !erroSentimentoPorTempo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorTempo?.length == 0 && !erroSentimentoPorTempo
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorTempo">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile> -->

        <!-- -------------Gráfico sentimento por tempo mensal ---------------- -->
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Classificação (Linhas e Barras)'
            )
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            *ngIf="
              dataSentimentoPorTempoMes &&
              dataSentimentoPorTempoMes.meses.length > 0 &&
              !erroSentimentoPorTempoMes
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="coresSentimento"
                [data]="dataSentimentoPorTempoMes"
                label="meses"
                title="Notícias por Classificação"
              ></app-stacked-bar-chart>

              <div class="tooltip-container-tempo">
                <mat-icon
                  matTooltipPosition="left"
                  matTooltipClass="red-tooltip"
                  matTooltip="O gráfico a seguir é apresentado quando o filtro de datas apresenta um intervalo maior que 31 dias. Apesar disso ele não é limitado pelos filtros de data."
                >
                  info_outlined</mat-icon
                >
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="!dataSentimentoPorTempoMes && !dataSentimentoPorTempoMes"
          >
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataSentimentoPorTempoMes?.meses?.length == 0 &&
              !erroSentimentoPorTempoMes
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Classificação</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroSentimentoPorTempoMes">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>

        <!-- ------------- Gráfico midia por tempo diário ---------------- -->
        <!-- <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Tipo de Mídia (Linhas e Barras)'
            ) && this.difDays < 31
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            class="chart-two-cols"
            *ngIf="
              dataMidiaPorTempo &&
              dataMidiaPorTempo.length > 0 &&
              !erroMidiaPorTempo
            "
          >
            <mat-card-content>
              <app-bar-chart-line-chart
                #charts
                [data]="midiaMidiaPorTempo !== null ? midiaMidiaPorTempo : []"
                [datas]="dataMidiaPorTempo"
                [color]="colorMidiaTempo"
                [cols]="numbergraphs"
                title="Notícias por Tipo de Mídia"
                (chartImage)="handleChartImage($event)"
                [(lastTypeSelected)]="typeSelectedNoticiasPorMidia"
              />
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataMidiaPorTempo && !erroMidiaPorTempo">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="dataMidiaPorTempo?.length == 0 && !erroMidiaPorTempo"
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Tipo de Mídia</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroMidiaPorTempo">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile> -->

        <!-- ------------- Gráfico midia por tempo mensal ---------------- -->
        <mat-grid-tile
          *ngIf="
            this.permissionCharts.includes(
              'Notícias por Tipo de Mídia (Linhas e Barras)'
            )
          "
          [colspan]="numbergraphs"
        >
          <mat-card
            class="chart-two-cols"
            *ngIf="
              dataMidiaPorTempoMes &&
              dataMidiaPorTempoMes.meses.length > 0 &&
              !erroMidiaPorTempoMes
            "
          >
            <mat-card-content>
              <app-stacked-bar-chart
                #charts
                (chartImage)="handleChartImage($event)"
                [color]="colorMidiaTempo"
                [data]="dataMidiaPorTempoMes"
                label="meses"
                title="Notícias por Tipo de Mídia"
              ></app-stacked-bar-chart>

              <div class="tooltip-container-tempo">
                <mat-icon
                  matTooltipPosition="left"
                  matTooltipClass="red-tooltip"
                  matTooltip="O gráfico a seguir é apresentado quando o filtro de datas apresenta um intervalo maior que 31 dias. Apesar disso ele não é limitado pelos filtros de data."
                >
                  info_outlined</mat-icon
                >
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="!dataMidiaPorTempoMes && !erroMidiaPorTempoMes">
            <mat-card-content class="loading-content">
              <mat-spinner diameter="50" color="accent"></mat-spinner>
            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="
              dataMidiaPorTempoMes?.meses?.length == 0 && !erroMidiaPorTempoMes
            "
          >
            <mat-card-content class="loading-content">
              <p>
                Não há dados para exibir em relação às
                <strong>Notícias por Tipo de Mídia</strong>
              </p>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="erroMidiaPorTempoMes">
            <mat-card-content class="loading-content">
              <p>Erro ao carregar gráfico</p>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </mat-tab>

  <!-- ----------------------------------Tabela----------------------------------------------------------------- -->
  <mat-tab label="Tabela Imprensa">
    <div>
      <h1>Tabela de dados</h1>
    </div>

    <mat-card *ngIf="carregandoTabela" class="card-tabela">
      <mat-card-content class="loading-content">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="tabelaVazia" class="card-tabela">
      <mat-card-content class="loading-content">
        <b>Não há dados para exibir</b>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!carregandoTabela && !tabelaVazia" class="card-tabela">
      <div style="padding: 10px">
        <button
          mat-raised-button
          (click)="gerarCSV()"
          class="button-login"
          color="primary"
        >
          Gerar CSV
        </button>
        <button
          mat-raised-button
          (click)="gerarPlanilha()"
          class="button-login"
          color="primary"
        >
          Gerar planilha
        </button>
      </div>

      <mat-card-content style="height: fit-content">
        <div class="mat-elevation-z8">
          <section class="example-container mat-elevation-z8" tabindex="0">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="example-container"
            >
              <!-- ID Column -->

              <ng-container
                [matColumnDef]="column"
                *ngFor="let column of displayedColumns"
              >
                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
              </ng-container>

              <!-- arquivo Column -->
              <ng-container matColumnDef="arquivo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Arquivo
                </th>
                <td mat-cell *matCellDef="let row">
                  <button
                    mat-button
                    *ngIf="
                      isArqNull(row.arquivo) &&
                      !isArqAndLinkNull(row.arquivo, row.link)
                    "
                    (click)="OpenLinkInNewTab(row.link)"
                  >
                    <mat-icon>link</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isArqAndLinkNull(row.arquivo, row.link)"
                  >
                    <b>Não há arquivo</b>
                  </button>

                  <button
                    mat-button
                    *ngIf="isAudioFile(row.arquivo)"
                    (click)="
                      openDialogAudio(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Veículo'],
                        row['Título'],
                        row.arquivo,
                        row.transcricao,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>audiotrack</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isPdfFile(row.arquivo)"
                    (click)="openDialogPdf(row.arquivo)"
                  >
                    <mat-icon>picture_as_pdf</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isImageFile(row.arquivo)"
                    (click)="
                      openDialogImg(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Veículo'],
                        row['Título'],
                        row.arquivo,
                        row.transcricao,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>image</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isVideoFile(row.arquivo)"
                    (click)="
                      openDialogVideo(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Veículo'],
                        row['Título'],
                        row.arquivo,
                        row.trasnscricao,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>ondemand_video</mat-icon>
                  </button>

                  <button
                    mat-button
                    *ngIf="isTextFile(row.arquivo)"
                    (click)="
                      openDialogTranscricao(
                        'imprensa',
                        row.keywords,
                        row['Mídia'],
                        row.link,
                        row['Data'],
                        row['Veículo'],
                        row['Título'],
                        row.arquivo,
                        row['Programa'],
                        row['Classificação']
                      )
                    "
                  >
                    <mat-icon>article</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns.concat('arquivo')"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumns.concat('arquivo')
                "
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow></tr>
            </table>

            <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              (page)="eventosTabela($event)"
              [length]="length"
              [pageSize]="rowsPerPage"
              [pageIndex]="paginaAtual - 1"
              [showFirstLastButtons]="true"
            >
            </mat-paginator>
          </section>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
