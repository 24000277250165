import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { JuridicoComponent } from './pages/juridico/juridico.component';
import { ImprensaComponent } from './pages/imprensa/imprensa.component';
import { LegislativoComponent } from './pages/legislativo/legislativo.component';
import {
  authGuard,
  authGuardAdmin,
  authGuardComunidade,
  authGuardImprensa,
  authGuardJuridico,
  authGuardLegislativo,
} from './core/guard/auth.guard';
import { HomeAdminComponent } from './pages/home-admin/home-admin.component';
import { ColunasComponent } from './pages/colunas/colunas.component';
import { GraficosComponent } from './pages/graficos/graficos.component';
import { TrocarSenhaComponent } from './pages/trocar-senha/trocar-senha.component';
import { EsqueciSenhaComponent } from './pages/esqueci-senha/esqueci-senha.component';
import { S } from '@angular/cdk/keycodes';
import { SolicitarRedefinicaoSenhaComponent } from './pages/solicitar-redefinicao-senha/solicitar-redefinicao-senha.component';
import { ComunidadeComponent } from './pages/comunidade/comunidade.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'imprensa',
        pathMatch: 'full',
      },
      {
        path: 'juridico',
        component: JuridicoComponent,
        canActivate: [authGuard, authGuardJuridico],
      },
      {
        path: 'imprensa',
        component: ImprensaComponent,
        canActivate: [authGuard, authGuardImprensa],
      },
      {
        path: 'legislativo',
        component: LegislativoComponent,
        canActivate: [authGuard, authGuardLegislativo],
      },
      {
        path: 'comunidade',
        component: ComunidadeComponent,
        canActivate: [authGuard, authGuardComunidade],
      },
    ],
  },
  {
    path: 'trocar-senha',
    component: TrocarSenhaComponent,
    canActivate: [authGuard],
  },
  {
    path: 'esqueci-senha/:token',
    component: EsqueciSenhaComponent,
  },
  {
    path: 'solicitar-redefinicao-senha',
    component: SolicitarRedefinicaoSenhaComponent,
  },
  {
    path: 'admin',
    component: HomeAdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'colunas',
        pathMatch: 'full',
      },
      {
        path: 'colunas',
        component: ColunasComponent,
        canActivate: [authGuard, authGuardAdmin],
      },
      {
        path: 'graficos',
        component: GraficosComponent,
        canActivate: [authGuard, authGuardAdmin],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
