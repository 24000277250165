import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrocarSenhaService } from 'src/app/core/services/trocar-senha.service';
import { UserService } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.scss'],
})
export class EsqueciSenhaComponent implements OnInit {
  hideNewPassword = true;
  hideConfirmNewPassword = true;
  novaSenhaCombina = false;
  validadeToken = 0;
  desativaBotao = true;
  isLoading = false;
  token: string | null = '';

  passwordForm = new FormGroup({
    novaSenha: new FormControl(''),
    confirmSenha: new FormControl(''),
  });

  constructor(
    private router: Router,
    private userService: UserService,
    private trocarSenhaService: TrocarSenhaService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.verificaSenha();
    this.token = this.route.snapshot.paramMap.get('token');

    this.trocarSenhaService.linkValido(this.token ?? '').subscribe({
      next: (response) => {
        if (response.status == 204) {
          this.validadeToken = 1;
        }
      },
      error: (error) => {
        if (error.status == 404) {
          this.validadeToken = 2;
        }
      },
    });
  }

  visibilidadeSenha(visibilidade: string) {
    if (visibilidade === 'novaSenha') {
      this.hideNewPassword = !this.hideNewPassword;
    } else if (visibilidade === 'confirmSenha') {
      this.hideConfirmNewPassword = !this.hideConfirmNewPassword;
    }
  }

  deslogar() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  Administrativo() {
    this.router.navigate(['admin/']);
  }

  TrocarSenha() {
    this.desativaBotao = true;
    this.isLoading = true;
    this.trocarSenhaService
      .esqueciSenha(this.passwordForm.value.novaSenha ?? '', this.token ?? '')
      .subscribe({
        next: (response) => {
          if (response.status == 204) {
            Swal.fire({
              icon: 'success',
              title: 'Troca de senha bem sucedida!',
              text: 'Você será redirecionado para o login.',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              confirmButtonColor: 'green',
            }).then(() => {
              this.isLoading = false;
              this.Voltar();
            });
          }
        },
        error: (error: { status: number; message: string }) => {
          this.isLoading = false;
          this.desativaBotao = false;
          if (
            (error.status === 400 && error.message.includes('código')) ||
            error.status === 404
          ) {
            this.validadeToken = 2;
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao redefinir a senha',
              text: 'Tente novamente mais tarde.',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              confirmButtonColor: 'red',
            });
          }
        },
      });
  }

  Voltar() {
    this.router.navigate(['login']);
  }

  verificaSenha() {
    this.passwordForm.controls.novaSenha.valueChanges.subscribe(() => {
      if (
        this.passwordForm.controls.novaSenha.value ==
        this.passwordForm.controls.confirmSenha.value
      ) {
        if (
          this.passwordForm.controls.novaSenha.value != '' &&
          this.passwordForm.controls.confirmSenha.value != ''
        ) {
          this.desativaBotao = false;
          this.novaSenhaCombina = true;
        }
      } else {
        this.novaSenhaCombina = false;
        this.desativaBotao = true;
      }
    });

    this.passwordForm.controls.confirmSenha.valueChanges.subscribe(() => {
      if (
        this.passwordForm.controls.novaSenha.value ==
        this.passwordForm.controls.confirmSenha.value
      ) {
        if (
          this.passwordForm.controls.novaSenha.value != '' &&
          this.passwordForm.controls.confirmSenha.value != ''
        ) {
          this.novaSenhaCombina = true;
          this.desativaBotao = false;
        }
      } else {
        this.novaSenhaCombina = false;
        this.desativaBotao = true;
      }
    });
  }
}
